
import {throwError as observableThrowError,  Observable ,  of } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';






import { environment } from '../../../../environments/environment';

import { ErrorService } from '../error/error.service';
import { AddNotification } from '../../helpers/notification.helpers';

@Injectable()
export class VimeoService {

    constructor (private router: Router,
                 private http: HttpClient,
                 private errorService: ErrorService) {}

    parseUrl (url) {
        let parsed
        try {
            parsed = new URL(url)
        } catch (error) {
            return
        }
        parsed['naked'] =
            parsed.origin +
            parsed.pathname
        parsed['naked'] =
            parsed['naked']
                .replace('.sd.mp4', '')
        parsed['naked'] =
            parsed['naked']
                .replace('/external/', '/video/')
        return parsed
    }

    getVimeoData (url) {
        const parsedUrl = this.parseUrl(url),
              nakedUrl = ( parsedUrl ) ? encodeURIComponent(parsedUrl['naked']) : false,
              vimeoUrl = `https://vimeo.com/api/oembed.json` +
                         `?url=${nakedUrl}` +
                         `&height=944` +
                         `&width=640`
        if ( !nakedUrl ) {
            return observableThrowError('Error')
        }
        return this.http
          .get(vimeoUrl).pipe(
          map((data) => {
            return data
          }),catchError(this.errorService.handleError),)
    }

}
