const blank = 'assets/svgs/img-placeholder-TYPE.svg',
    transparent = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

export class Image {
    id?: number
    product?: number

    constructor (data: any = {}) {
        Object.assign(this, data)
    }
}

export class ImageRef {
    product_image_url?: string
    product_image_url_local?: string
    product_image_lowres_url?: string
    product_image_thumbnail_url?: string
    productImage: string
    productLowres: string
    productThumbnail: string

    constructor (data: any = {}) {
        Object.assign(this, data)

        this.productImage = this.getProductImage('square')
        this.productLowres = this.getProductLowres()
        this.productThumbnail = this.getProductThumbnail()
    }

    getProductImage (type: string = 'square') {
        return this.product_image_url_local || this.product_image_url || blank.replace('TYPE', type)
    }

    getProductLowres () {
        return this.product_image_url_local || this.product_image_lowres_url
                || this.product_image_url || transparent
    }

    getProductThumbnail () {
        return this.product_image_url_local || this.product_image_lowres_url ||
                // must get the full resolution version for product uploads page
                this.product_image_url || transparent
    }
}
