import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ErrorService } from '../error/error.service';





@Injectable()
export class S3UploadService {

  token: ''
  headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  constructor (private http: HttpClient,
               private router: Router,
               private errorService: ErrorService) {}

  uploadMedia (link: string,
               fileType: string,
               media: ArrayBuffer): Observable<any> {
    return this.http
      .put(link, media, {
        headers: new HttpHeaders({
          'Content-Type': fileType
        })
      }).pipe(catchError(this.errorService.handleError))
  }

  deleteCardMedia (id: number, type: string, path: string): Observable<any> {
    const url = `/cards/${id}/resource?card_type=${type}&resource_type=${path}`
    return this.http
      .delete(url).pipe(
      catchError(this.errorService.handleError))
  }

  getUploadLink (file = null): Observable<any> {
    let url = '/files/upload'
    if ( file ) {
      file = file.toString().replace(/[^\w\s]/gi, '').toLowerCase()
      url += `?file_name=${ encodeURIComponent(file) }`
    }
    return this.http
      .get(url).pipe(
      map(result => {
        return result
      }),
      catchError(this.errorService.handleError),)
  }

}
