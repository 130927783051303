export class Subscription {
  plan: string
  start_at: Date
  end_at: Date
  paid_at: Date
  cancelled_at: Date
  error_count: number
  coupon: string

  constructor(data: any = {}) {
    Object.assign(this, data)
  }
}
