import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
    transform(array: Array<any>, field: string): any {
        array = array || []

        if (field === '') {
            return array
        }

        const reverse = field.indexOf('-') === 0

        if (reverse) {
            field = field.substring(1)
        }

        array.sort((a: any, b: any) => {
            a = Object.assign({}, a)
            b = Object.assign({}, b)

            if (typeof a[field] === 'string') {
                a[field] = a[field].toLowerCase()
                b[field] = b[field].toLowerCase()
            }

            if (reverse) {
                return a[field] > b[field] ? -1 : 1
            } else {
                return a[field] < b[field] ? -1 : 1
            }
        })

        return array
    }
}
