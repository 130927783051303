import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Story } from '../../models';
import { StoryService, StoryPublishService } from '../../services';

@Component({
    selector: 'app-buttons',
    templateUrl: './buttons.component.html',
    styleUrls: [ './buttons.component.scss' ]
})
export class ButtonsComponent {
    @Input() story: Story
    @Input() cards: any
    @Input() openPreview: any
    @Input() desktopSrc: any
    @Input() storyId: any
    @Input() publishStory: any
    @Input() unpublishStory: any
    @Input() embedStory: any
    @Input() hasAccess: any
    @Input() review: any
    @Input() context: string

    constructor (
        private storyPublishService: StoryPublishService
    ) {}

    onPublishClick (event) {
        this.storyPublishService.fire()
    }

    onReviewClick(event) {
        this.review()
    }
}
