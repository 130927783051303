import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
    AuthService,
    TeamService
} from '../../services';

import { User } from '../../models/user/user.model';
import { Company } from '../../models/company/company.model';

import { DisplayDate } from '../../helpers/date.helpers';
import { AddNotification } from '../../helpers/notification.helpers';
import { UserHasAccess } from '../../helpers/menu.helpers';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
    user: User
    company: Company
    UserHasAccess: Function

    DisplayDate
    specialMessage: string
    subscriptions: any = []
    isInTrialPeriodAlertHidden = false

    constructor (
        private authService: AuthService,
        private teamService: TeamService
    ) {
        this.user = this.authService.user
        this.company = this.user.company

        this.DisplayDate = DisplayDate

        this.UserHasAccess = (path) => {
            return UserHasAccess(this.user, this.teamService.teams, path)
        }

        this.subscriptions.push(this.authService.user$.subscribe((user) => {
            this.user = user
            this.company = this.user.company

            // XXX what was this supposed to be? there is no "status" field on the user object.
            // at any rate, it doesn't work.
            // if (!user.status) {

            // XXX "trailMessageShown" is very poorly named, considering what its purpose is
            if (!this.user.preferences.trialMessageShown) {
                this.showSpecialMessage()
            }
        }))
    }

    ngOnInit () {
        this.showSpecialMessage()

        // localStorage saves its data as a string, so have to do a string compare
        this.isInTrialPeriodAlertHidden = localStorage.getItem('home_trial_alert_hidden') === 'true'
    }

    ngOnDestroy () {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        })
    }

    onCloseClick () {
        this.isInTrialPeriodAlertHidden = true
        localStorage.setItem('home_trial_alert_hidden', 'true')
    }

    showSpecialMessage () {
        if (this.company.in_trial_period) {
            this.specialMessage = 'Congratulations. Your free 30 day trial has begun, and will expire on '
                + DisplayDate(String(this.company.trial_end_at)) + '.'
        } else if (
            this.company
            && this.company.payment_details
            && this.company.subscription
            && this.company.subscription.error_count
        ) {
            this.specialMessage = 'Your payment has failed. Please update your payment method.'
        }

        if (this.specialMessage) {
            setTimeout(() => {
                this.specialMessage = null
            }, 10000)
        }

        if (!this.user.preferences.trialMessageShown) {
            this.subscriptions.push(this.authService.saveUser(
                    { preferences: { trialMessageShown: true } }
                ).subscribe(() => {}))
        }
    }
}
