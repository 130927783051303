import { Component, OnDestroy, Input } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { User, Company } from '../../models';

import { SetModal, UserHasAccess } from '../../helpers';

import { AuthService, TeamService } from '../../services';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: [ './header.component.scss' ]
})
export class HeaderComponent implements OnDestroy {
    user: User
    company: Company
    UserHasAccess: Function

    pushRightClass: any = 'push-right'
    subscribers: any = []
    accountMenus: any = [
        '/settings',
        '/account/overview',
        '/account/plans-and-pricing',
        '/account/billing',
        '/products',
        '/logos',
    ]
    showAccountSubMenu = false

    constructor (
        private translate: TranslateService,
        private authService: AuthService,
        private teamService: TeamService,
        public router: Router
    ) {
        this.user = this.authService.user
        this.company = this.user.company

        this.UserHasAccess = (path) => {
            return UserHasAccess(this.user, this.teamService.teams, path)
        }

        this.subscribers.push(this.router.events
            .subscribe(event => {
                if ( event instanceof NavigationEnd  ) {
                    if ( window.innerWidth <= 992 &&
                        this.isToggled() ) {
                        this.toggleSidebar()
                    }

                    this.showAccountSubMenu = !!this.accountMenus.includes(event.url.split('?')[0])
                }
            }))

        this.subscribers.push(this.authService.user$.subscribe((user) => {
            this.user = user
            this.company = this.user.company || new Company({})
        }))
    }

    ngOnDestroy () {
        this.subscribers.forEach(subscriber => {
            subscriber.unsubscribe()
        })
    }

    isToggled (): boolean {
        const dom: Element = document.querySelector('body')
        return dom.classList.contains(this.pushRightClass)
    }

    toggleSidebar () {
        const dom: any = document.querySelector('nav.sidebar')
        dom.classList.toggle('open')
    }

    rltAndLtr () {
        const dom: any = document.querySelector('body')
        dom.classList.toggle('rtl')
    }

    changeLang (language: string) {
        this.translate.use(language)
    }
    gotoAdmin() {
        this.authService.gotoAdmin().subscribe((data: any) => {
           window.open(data.url, '_blank') 
        });
    }
}
