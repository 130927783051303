import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
    transform(array: Array<any>, type: string): any {
        array = array || []

        if (type === '') {
            return array
        }

        return array.filter(item => item.asset_type === type)
    }
}

@Pipe({
    name: 'filterBySubcategory'
})
export class FilterBySubcategoryPipe implements PipeTransform {
    transform(array: Array<any>, subcategoryIds: Array<number>): any {
        array = array || []

        if (subcategoryIds.length === 0) {
            return array
        }

        return array.filter(item => {
            // XXX this result produces an "OR" result of the given subcategoryIds
            // it would need to be changed to produce an "AND" result if that's requested
            const found = item.categories.find(subcategory => subcategoryIds.includes(subcategory.id))
            return found
        })
    }
}
