export class Modal {

  type: string
  title: string
  content: string
  callback?: any
  choices?: any
  items?: any
  selected?: any
  center?: boolean

  constructor(data: any = {}) {
    Object.assign(this, data)
    if ( !this.selected ) {
      this.selected = []
    }
  }

}
