import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';

import { User } from '../../models';
import {
    AddNotification,
    displayCityAndState,
    // displayCountryAndState
} from '../../helpers';

import {
    UserService,
    AuthService,
    SharedService
} from '../../services';

@Component({
    selector: 'app-user-item',
    templateUrl: './user-item.component.html',
    styleUrls: ['./user-item.component.scss']
})
export class UserItemComponent implements OnDestroy, OnInit {
    @Input() item: User
    @Input() check: boolean
    @Input() checked: boolean
    @Input() readonly: boolean
    @Input() mode: string
    @Input() emitter: any

    displayCityAndState: Function
    // displayCountryAndState: Function
    user: User

    isOnTeams = false
    duplicate: boolean
    subscribers: any = []

    constructor (
        private authService: AuthService,
        private userService: UserService,
        private sharedService: SharedService
    ) {
        this.user = this.authService.user

        this.displayCityAndState = displayCityAndState
        // this.displayCountryAndState = displayCountryAndState
    }

    ngOnInit () {
        this.duplicate = this.item.role === 'company_admin' && this.mode !== 'company_admin'

        // if ( this.duplicate || !this.item.confirmed ) {
        if ( this.duplicate ) {
            this.check = false
        }

        if ( this.item.story_team_count > 0 ) {
            this.isOnTeams = true
        }
    }

    ngOnDestroy () {
        this.subscribers.forEach(subscriber => {
            subscriber.unsubscribe()
        })
    }

    confirmedDeleteUser (event) {
        this.subscribers.push(this.userService
            .deleteUser(this.item)
            .subscribe(() => {
                this.emitter(this.mode + '_deleted', this.item)
                AddNotification({
                    title: 'Success',
                    text: this.getLabel() + ' deleted successfully',
                    hideConfirm: true,
                }, 3000)
            }, () => {}))
    }

    deleteUser (event) {
        event.preventDefault()

        const args = {
            item: 'user',
            customTitle: 'Are you sure you want to delete ' + this.getLabel() + '?'
        }

        this.openModal('delete', args, (choice) => {
            if (choice && choice.action === 'delete') {
                this.confirmedDeleteUser(event)
            }
        })
    }

    openModal (type: string, args: any, callback: any) {
        this.sharedService.triggerModal(type, callback, args)
    }

    getLabel () {
        let label;
        switch (this.mode) {
            case 'company_admin':
                label = 'Storymaster'
                break;
            case 'publisher':
                label = 'Publisher'
                break;
            case 'editor':
                label = 'Editor'
                break;
            case 'contributor':
                label = 'StorySource'
                break;
        }
        return label || ''
    }

    checkboxClick (event) {
        const { id, checked } = event.target
        if (checked) {
            this.emitter('member_selected', null, { id, role: this.mode })
        } else  {
            this.emitter('member_unselected', null, { id, role: this.mode })
        }
    }
}
