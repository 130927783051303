import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserItemComponent } from './user-item.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        UserItemComponent
    ],
    exports: [
        UserItemComponent
    ],
    providers: []
})
export class UserItemModule {}
