
import {throwError as observableThrowError, of as observableOf,  Observable ,  Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { User } from '../../models/user/user.model';

import { ErrorService } from '../error/error.service';
import { AddNotification } from '../../helpers/notification.helpers';






@Injectable()
export class AuthService {
  user: User
  user$

  postLoginUrl: string

  headers: any = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  constructor (
    private http: HttpClient,
    private router: Router,
    private errorService: ErrorService
  ) {
    this.user$ = new Subject<any>()
    this.user = new User({})
  }

  getHeaders (): any {
    const email = (this.user && this.user.email) || localStorage.getItem('email'),
      token = localStorage.getItem('token'),
      headers = {}

    if (email) {
      headers['X-USER-EMAIL'] = email
    }

    if (token) {
      headers['X-USER-TOKEN'] = token
    }

    return new HttpHeaders(Object.assign(this.headers, headers))
  }

  login (user: any): Observable<any> {
    localStorage.removeItem('email')
    localStorage.removeItem('token')

    return this.http
      .post('/login', { user }).pipe(
      map((response: HttpResponse<any>) => {
        localStorage.setItem('email', user['email'])
        localStorage.setItem('token', response['authentication_token'])

        return this.current()
      }),catchError(this.errorService.handleError),)
  }

  logout (): Observable<any> {
    return this.http
      .delete('/logout').pipe(
      map((response: HttpResponse<any>) => {
        localStorage.removeItem('email')
        localStorage.removeItem('token')

        this.user = new User({})
        this.user$.next(this.user)

        return response || {}
      }),catchError(this.errorService.handleError),)
  }

  signup (user: any): Observable<any> {
    return this.http
      .post('/signup', { user }).pipe(
      map((response: HttpResponse<any>) => {
        localStorage.removeItem('email')
        localStorage.removeItem('token')
        return response || {}
      }),catchError(this.errorService.handleError),)
  }

  forgot (user: any): Observable<any> {
    return this.http
      .post('/users/password', { user }).pipe(
      map((response: HttpResponse<any>) => {
        localStorage.removeItem('email')
        localStorage.removeItem('token')
        return response || {}
      }),catchError(this.errorService.handleError),)
  }

  reset (user: any): Observable<any> {
    return this.http
      .put('/users/password', { user }).pipe(
      map((response: HttpResponse<any>) => {
        localStorage.removeItem('email')
        localStorage.removeItem('token')
        return response || {}
      }),catchError(this.errorService.handleError),)
  }

  confirm (user: any): Observable<any> {
    return this.http
      .post('/confirm', { user }).pipe(
      map((response: HttpResponse<any>) => {
        localStorage.removeItem('email')
        localStorage.removeItem('token')
        return response || {}
      }),catchError(this.errorService.handleError),)
  }

  reconfirm (user: any): Observable<any> {
    return this.http
      .post('/reconfirm', { user }).pipe(
      map((response: HttpResponse<any>) => {
        return response || {}
      }),catchError(this.errorService.handleError),)
  }

  accept (user: any): Observable<any> {
    return this.http
      .post('/accept', { user }).pipe(
      map((response: HttpResponse<any>) => {
        localStorage.removeItem('email')
        localStorage.removeItem('token')
        return response || {}
      }),catchError(this.errorService.handleError),)
  }

  handleError (error) {
    if ( error.status === 401 ) {
      localStorage.removeItem('email')
      localStorage.removeItem('token')

      if ( this.router && this.router.navigate ) {
        this.router.navigate([ '/', 'login' ])
      } else {
        window.location.href = '/#/login'
      }

      return observableOf(error)
    } else {
      return observableThrowError(error)
    }
  }

  // XXX this returnOn401Error method is sloppy and we need a better long term solution
  current (returnOn401Error?: boolean): Observable<any> {
    return this.http
      .get('/users/current').pipe(
      map((response: HttpResponse<any>) => {
        this.user = new User(response)

        this.user$.next(this.user)

        return this.user
      }, (error) => {
        if (error.status === 401 && returnOn401Error) {
          localStorage.removeItem('email')
          localStorage.removeItem('token')

          return observableOf(error)
        } else {
          return this.handleError(error)
        }
      }),catchError((err) => {
        return this.handleError(err)
      }),)
  }

  saveUser (user: any): Observable<User> {
    return this.http
      .put<User>('/users/current', { user }).pipe(
      map((data) => {
        this.user = new User(data)

        this.user$.next(this.user);

        return this.user
      }),catchError(this.errorService.handleError),)
  }
  
  gotoAdmin () {
    return this.http
      .get('/admin-url', { withCredentials: true })
  }
}
