import { UserSummary } from './userSummary.model';
import { ProductSummary } from './productSummary.model';
import { StorySummary } from './storySummary.model';
import { LogoSummary } from './logoSummary.model';

export class CompanySummary {
  users: UserSummary
  products: ProductSummary
  stories: StorySummary
  logos: LogoSummary

  constructor(data: any = {}) {
    Object.assign(this, data)
    this.users = new UserSummary(this.users)
    this.products = new ProductSummary(this.products)
    this.stories = new StorySummary(this.stories)
    this.logos = new LogoSummary(this.logos)
  }
}
