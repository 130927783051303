
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Image } from '../../models/image/image.model';
import { Product } from '../../models/product/product.model';
import { ErrorService } from '../error/error.service';




@Injectable()
export class ImageService {
    constructor (
        private http: HttpClient,
        private errorService: ErrorService
    ) {}

    createImage (image: Image): Observable<Image> {
        const url = `/products/${image.product}/images`
        return this.http
            .post<Image>(url, { product: image }).pipe(
            map((data) => {
                return new Product(data)
            }),catchError(this.errorService.handleError),)
    }

    deleteImage (image: Image): Observable<Image> {
        const url = `/product-images/${image.id}`
        return this.http
            .delete<Image>(url).pipe(
            catchError(this.errorService.handleError))
    }
}
