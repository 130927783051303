import { Component, OnInit, OnDestroy, Input, ViewChild, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
    User,
    Asset,
    Category
} from '../../models';

import {
    AddNotification
} from '../../helpers';

import {
    AuthService,
    CategoryService,
    SharedService
} from '../../services';

@Component({
    selector: 'app-categories-container',
    templateUrl: './categories-container.component.html',
    styleUrls: ['./categories-container.component.scss']
})
export class CategoriesContainerComponent implements OnDestroy, OnInit {
    @Input() categoryIds: any
    @Input() subcategoryIds: any
    @Input() context: string
    @Input() emitter: any

    user: User
    categories: Category[]

    dirty = false
    subscribers: any = []

    uncategorizedCategoryId: number
    uncategorizedSubcategoryId: number

    isCategoriesModalShown = false
    subcategories: string
    selectedCategories: any = []
    checkedCategories: any = {}
    checkedSubcategories: any = {}
    expandedCategories: any = {}

    @ViewChild('categoriesModal', { static: true }) categoriesModal: ElementRef

    constructor (
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private categoryService: CategoryService,
        private sharedService: SharedService
    ) {
        this.user = this.authService.user
        this.categoryIds = []
    }

    @HostListener ('document:click', ['$event'])
    clickout (event) {
        if (
            this.isCategoriesModalShown === true
            && !this.categoriesModal.nativeElement.contains(event.target)
        ) {
            this.isCategoriesModalShown = false
        }
    }

    ngOnInit () {
        this.subcategories = this.subcategoryIds.join(',')

        if (!this.categoryService.categories || this.categoryService.categories.length === 0) {
            this.subscribers.push(this.categoryService
                .getCategories()
                .subscribe((response: any) => {
                    this.processCategories()
                }, error => {}))
        } else {
            this.processCategories()
        }
    }

    ngOnDestroy () {
        this.subscribers.forEach(subscriber => {
            subscriber.unsubscribe()
        })
    }

    processCategories () {
        this.uncategorizedCategoryId = this.categoryService.uncategorizedCategoryId
        this.uncategorizedSubcategoryId = this.categoryService.uncategorizedSubcategoryId

        this.categories = this.categoryService.categories.filter(category => category.parent_id === null)

        this.categories.forEach(category => {
            this.checkedCategories[category.id] = {
                checked: this.categoryIds.includes(category.id),
                subcategoryIds: [],
            }

            this.expandedCategories[category.id] = this.categoryIds.includes(category.id)

            category.children.forEach(subcategory => {
                this.checkedCategories[category.id].subcategoryIds.push(subcategory.id)

                this.checkedSubcategories[subcategory.id] = {
                    checked: this.subcategoryIds.includes(subcategory.id),
                    parentId: category.id,
                }
            })
        })

        this.selectedCategories = this.categories
            .filter(category => this.categoryIds.includes(category.id))
            .map(category => ({
                name: category.name,
                subcategories: category.children
                    .filter(subcategory => this.subcategoryIds.includes(subcategory.id))
                    .map(subcategory => ({ name: subcategory.name }))
            }))
    }

    onCategoriesContainerClick (event) {
        event.preventDefault()
        event.stopPropagation()

        this.isCategoriesModalShown = true
    }

    onCategoryClick (event, categoryId: number) {
        event.preventDefault()
        event.stopPropagation()

        if (categoryId === this.uncategorizedCategoryId) {
            return
        }

        if (event.target.type === 'checkbox') {
            const category = this.checkedCategories[categoryId]

            category.checked = !category.checked

            category.subcategoryIds.forEach(subcategoryId => {
                this.checkedSubcategories[subcategoryId].checked = category.checked
            })

            this.expandedCategories[categoryId] = category.checked
        } else {
            this.expandedCategories[categoryId] = !this.expandedCategories[categoryId]
        }

        this.setUncategorizedCheckboxes()
    }

    isCategoryChecked (categoryId: number) {
        return this.checkedCategories[categoryId].checked
    }

    onSubcategoryClick (subcategoryId: number) {
        event.preventDefault()
        event.stopPropagation()

        if (subcategoryId === this.uncategorizedSubcategoryId) {
            return
        }

        const subcategory = this.checkedSubcategories[subcategoryId]

        subcategory.checked = !subcategory.checked

        const foundId = this.checkedCategories[subcategory.parentId].subcategoryIds.find(
            id => this.checkedSubcategories[id].checked
        )

        this.checkedCategories[subcategory.parentId].checked = foundId ? true : false

        this.setUncategorizedCheckboxes()
    }

    isSubcategoryChecked (subcategoryId: number) {
        return this.checkedSubcategories[subcategoryId].checked
    }

    setUncategorizedCheckboxes () {
        const foundId = Object.keys(this.checkedCategories).find(
            key => this.checkedCategories[key].checked
        )

        this.checkedCategories[this.uncategorizedCategoryId].checked = foundId ? false : true
        this.checkedSubcategories[this.uncategorizedSubcategoryId].checked = foundId ? false : true
        this.expandedCategories[this.uncategorizedCategoryId] = foundId ? false : true
    }

    isCategoryExpanded (categoryId: number): boolean {
        return this.expandedCategories[categoryId]
    }

    displaySubcategories (category: any): string {
        return category.subcategories.map(subcategory => subcategory.name).join(', ')
    }

    onCategoriesModalSaveClick (event) {
        event.preventDefault()

        this.selectedCategories = this.categories
            .filter(category => this.checkedCategories[category.id].checked)
            .map(category => ({
                name: category.name,
                subcategories: category.children
                    .filter(subcategory => this.checkedSubcategories[subcategory.id].checked)
                    .map(subcategory => ({ name: subcategory.name }))
            }))

        this.subcategories = (
            Object.keys(this.checkedSubcategories)
                .filter(subcategoryId => this.checkedSubcategories[subcategoryId].checked)
        ).join(',')

        if (this.subcategories) {
            this.emitter('subcategories', this.subcategories)
        }

        this.isCategoriesModalShown = false
    }
}
