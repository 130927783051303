export class Address {
    address1: string
    address2: string
    city: string
    state: string
    zip: string
    country: string

    constructor(data: any = {}) {
        Object.assign(this, data)
    }
}
