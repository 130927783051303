import { Notification } from '../models/notification/notification.model';

export const NOTIFICATIONS = []

let placeholder = null

const checkForDuplicate = (notification) => {
    for (const INDEX in NOTIFICATIONS) {
        if ( NOTIFICATIONS[INDEX]['title'] === notification.title &&
             NOTIFICATIONS[INDEX]['text'] === notification.text ) {
            return true
        }
    }
    return false
}

export const AddNotification = (notification: any, timeout?: number, callback?: any, persist?: boolean) => {
    notification = new Notification(notification)

    if ( !checkForDuplicate(notification) ) {
        NOTIFICATIONS.push(notification)
        if ( !persist ) {
            window.addEventListener(
                'click',
                cancel.bind(
                    this,
                    {},
                    notification,
                    callback
                ),
                true
            )
        }
        if ( timeout && typeof timeout === 'number' ) {
            placeholder = setTimeout(() => {
                RemoveNotification(notification)
                placeholder = null
                if ( callback ) {
                    callback()
                }
            }, timeout)
        } else {
            if ( callback ) {
                callback()
            }
        }
    } else {
        if ( timeout && placeholder ) {
            clearTimeout(placeholder)
            placeholder = setTimeout(() => {
                RemoveNotification(notification)
                placeholder = null
                if ( callback ) {
                    callback()
                }
            }, timeout)
        } else {
            if ( callback ) {
                callback()
            }
        }
    }
}

export const RemoveNotification = (notification: any) => {
    notification = new Notification(notification)
    const index = NOTIFICATIONS.indexOf(notification)
    RemoveNotificationByIndex(index)
}

export const RemoveNotificationByIndex = (index: number) => {
    NOTIFICATIONS.splice(index, 1)
}

export const RemoveAllNotifications = () => {
    NOTIFICATIONS.splice(0, NOTIFICATIONS.length)
}

const cancel = (event, notification, callback) => {
    RemoveNotification(notification)
    window.removeEventListener(
        'click',
        cancel.bind(
            this,
            {},
            notification,
            callback
        ),
        true
    )
    placeholder = null
    if ( callback ) {
        callback()
    }
}
