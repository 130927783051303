
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { User } from '../../models/user/user.model';
import { ErrorService } from '../error/error.service';

@Injectable()
export class StoryTeamService {
    constructor (
        private router: Router,
        private http: HttpClient,
        private errorService: ErrorService
    ) {}

    getStoryTeam (id: number): Observable<any> {
        const url = `/stories/${id}/team`
        return this.http
            .get(url).pipe(
            map((data: any) => {
                return data.members.map(member => new User(member))
            }),catchError(this.errorService.handleError),)
    }

    saveStoryTeam (id: number, members: any): Observable<any> {
        const url = `/stories/${id}/team`
        return this.http
            .put(url, { story: { ...members }}).pipe(
            map((data: any) => {
                return { success: true }
            }),catchError(this.errorService.handleError),)
    }

    sendUploadLink (id: number, recipientId: number, message: string): Observable<any> {
        const url = `/stories/${id}/notify/upload-link`,
              params = { story: { recipient_id: recipientId, message } }
        return this.http
            .post(url, params).pipe(
            map((data: any) => {
                return { success: true }
            }),catchError(this.errorService.handleError),)
    }
}
