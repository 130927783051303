import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafeUrlPipe } from './safeurl.pipe';
import { SafeHtmlPipe } from './safehtml.pipe';
import { OrderByPipe } from './orderby.pipe';
import { FilterByPipe, FilterBySubcategoryPipe } from './filterby.pipe';
import { RoundPipe } from './round.pipe';
import { DisplayDatePipe } from './display-date.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        SafeUrlPipe,
        SafeHtmlPipe,
        OrderByPipe,
        FilterByPipe,
        FilterBySubcategoryPipe,
        RoundPipe,
        DisplayDatePipe
    ],
    exports: [
        SafeUrlPipe,
        SafeHtmlPipe,
        OrderByPipe,
        FilterByPipe,
        FilterBySubcategoryPipe,
        RoundPipe,
        DisplayDatePipe
    ]
})
export class SharedPipesModule {}
