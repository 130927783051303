import { transparent, placeholder } from '../../helpers'

const comingSoonUrl = '/assets/images/testing/video-thumbnail-coming-soon.png'

export class StoryAsset {
    id: number
    name: string
    description: string
    asset_type: string
    story_id: number
    user_id: number
    added_to_library: boolean
    blockchain_success?: boolean
    blockchain_transaction_id?: string
    // actually story assets don't have categories or tags, but these attributes
    // are  necessary so the media library code won't break
    categories?: any
    tags?: any
    created_at: Date
    updated_at: Date

    item_url?: string
    item_url_local?: string
    item_lowres_url?: string
    item_thumbnail_url?: string
    item_mpeg_url?: string

    constructor (data: any = {}) {
        Object.assign(this, data)

        if (!this.categories) {
            this.categories = []
        }
    }

    assetImage (type: string) {
        return this.item_url || placeholder(type)
    }

    assetLowres () {
        if (this.asset_type === 'video') {
            return this.item_url_local || this.item_lowres_url || comingSoonUrl
        } else {
            return this.item_url_local || this.item_lowres_url || this.item_url || transparent
        }
    }

    assetThumbnail (type: string) {
        if (this.asset_type === 'video') {
            return this.item_thumbnail_url || comingSoonUrl
        } else {
            return this.item_thumbnail_url || this.item_url || placeholder(type)
        }
    }
}
