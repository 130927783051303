import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TutorialTooltipComponent } from './tutorial-tooltip.component';

@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        RouterModule
    ],
    declarations: [
        TutorialTooltipComponent
    ],
    exports: [
        TutorialTooltipComponent
    ]
})
export class TutorialTooltipModule {}
