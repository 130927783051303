import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-page-footer',
    templateUrl: './page-footer.component.html',
    styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent {
    copyrightYear: string

    constructor () {
        this.copyrightYear = moment().format('YYYY')
    }
}
