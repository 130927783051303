import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AlertPlansComponent } from './alert-plans.component';

@NgModule({
    imports: [
        CommonModule,
        NgbModule
    ],
    declarations: [
        AlertPlansComponent
    ],
    exports: [
        AlertPlansComponent
    ],
    providers: [
    ]
})
export class AlertPlansModule {}
