import { User } from '../user/user.model';
import { Company } from '../company/company.model';

export class Team {
  id: number
  name: string
  company: Company
  members?: User[]

  constructor (data: any = {}) {
    Object.assign(this, data)

    this.company = new Company(this.company)

    if (!this.members) {
      this.members = []
    }

    this.members = this.members.map(member => {
      return new User(member)
    })
  }
}
