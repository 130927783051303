import { transparent, placeholder, pluralize } from '../../helpers'

import { Logo } from '../logo/logo.model';
import { Category } from '../category/category.model';

export class Story {
    id?: number
    title: string
    subtitle: string
    url: string
    display_company_logo?: boolean
    display_preview_image?: boolean

    cover_image_asset_id?: number
    cover_image_asset_url?: string
    cover_image_asset_url_local?: string
    cover_image_asset_widget_url?: string
    cover_image_asset_lowres_url?: string
    cover_image_thumbnail_url_local?: string
    cover_image_asset_thumbnail_url?: string

    preview_image_asset_id?: number
    preview_image_asset_url?: string
    preview_image_asset_url_local?: string
    preview_image_asset_lowres_url?: string
    preview_image_asset_widget_url?: string
    preview_image_asset_display_url?: string
    preview_image_thumbnail_url_local?: string
    preview_image_asset_thumbnail_url?: string

    preview_text?: string
    commercial_name?: string
    company_logo?: any
    company_logo_id?: number
    edited: boolean
    published: boolean
    archived: boolean
    created_at: Date
    updated_at: Date
    date?: Date
    age?: string
    token?: string
    categories: Category[]
    category_ids?: number[]
    team_id?: number
    in_review?: boolean
    generated_title?: boolean

    constructor (data: any = {}) {
        Object.assign(this, data)
        this.company_logo = new Logo(this.company_logo)
        this.created_at = new Date(this.created_at) || new Date(0)
        this.updated_at = new Date(this.updated_at) || new Date(this.created_at) || new Date(0)
        this.date = new Date(this.updated_at) || new Date(this.created_at) || new Date(0)
        let now = new Date().getTime(), created = this.created_at.getTime()

        if (isNaN(now)) {
            now = 0
        }

        if (isNaN(created)) {
            created = 0
        }

        const diff = now - created,
            weeks = Math.round(diff / 604800000),
            months = Math.round(diff / 2592000000)

        if (weeks < 5) {
            this.age = weeks + ' ' + pluralize('week', weeks) + ' old'
        } else {
            this.age = months + ' ' + pluralize('month', months) + ' old'
        }

        this.categories = (data.categories || []).map(category => new Category(category))
        this.category_ids = this.categories.map(category => category.id)
    }

    hasCoverImage () {
        return this.cover_image_asset_url_local || this.cover_image_asset_url ? true : false
    }

    coverImage (type: string) {
        return this.cover_image_asset_url_local || this.cover_image_asset_url || placeholder(type)
    }

    coverLowres () {
        return this.cover_image_asset_url_local || this.cover_image_asset_lowres_url
            || this.cover_image_asset_url || transparent
    }

    coverThumbnail (type: string) {
        return this.cover_image_thumbnail_url_local || this.cover_image_asset_thumbnail_url || placeholder(type)
    }

    hasPreviewImage () {
        return this.preview_image_asset_url_local || this.preview_image_asset_url ? true : false
    }

    previewImage (type: string) {
        return this.preview_image_asset_url_local || this.preview_image_asset_url || placeholder(type)
    }

    previewLowres () {
        return this.preview_image_asset_url_local || this.preview_image_asset_lowres_url
            || this.preview_image_asset_url || transparent
    }

    previewThumbnail (type: string) {
        return this.preview_image_thumbnail_url_local || this.preview_image_asset_url_local
            || this.preview_image_asset_thumbnail_url || placeholder(type)
    }

    previewSmallThumbnail () {
        return this.preview_image_asset_display_url || transparent
    }
}
