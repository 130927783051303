
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';




import { Asset, Story, StoryAsset } from '../../models';

import { ErrorService } from '../error/error.service';

@Injectable()
export class AssetService {
    constructor (
        private http: HttpClient,
        private errorService: ErrorService
    ) {}

    createAsset (asset: any): Observable<Asset> {
        const url = `/assets`
        return this.http
            .post(url, { asset }).pipe(
            map((data) => {
                return new Asset(data)
            }),catchError(this.errorService.handleError),)
    }

    createAssetFromStoryUpload (storyId: number, asset: any): Observable<Asset> {
        const url = `/assets?story_id=${storyId}`
        return this.http
            .post(url, { asset: asset }).pipe(
            map((data) => {
                return new Asset(data)
            }),catchError(this.errorService.handleError),)
    }

    saveStoryAssetToLibrary (storyAssetId: number, asset: any): Observable<Asset> {
        const url = `/assets?story_asset_id=${storyAssetId}`
        return this.http
            .post(url, { asset: asset }).pipe(
            map((data) => {
                return new Asset(data)
            }),catchError(this.errorService.handleError),)
    }

    deleteStoryAsset (storyAsset: StoryAsset): Observable<StoryAsset> {
        const url = `/story-assets/${storyAsset.id}`
        return this.http
            .delete<StoryAsset>(url).pipe(
            catchError(this.errorService.handleError))
    }

    setStoryImageAsset (storyId: number, story: any): Observable<Story> {
        const url = `/stories/${storyId}`
        return this.http
            .put(url, { story }).pipe(
            map((data) => {
                return new Story(data)
            }),catchError(this.errorService.handleError),)
    }

    getAllStoryAssets (storyId: number): Observable<StoryAsset> {
        const url = `/stories/${storyId}/assets`
        return this.http
            .get(url).pipe(
            map((data: any) => {
                const storyAssets = data.map(storyAsset => new StoryAsset(storyAsset))
                return storyAssets
            }),
            catchError(this.errorService.handleError),)
    }

    getContributorStoryAssets (storyId: number, userId: number): Observable<StoryAsset> {
        const url = `/stories/${storyId}/assets?user_id=${userId}`
        return this.http
            .get(url).pipe(
            map((data: any) => {
                const storyAssets = data.map(storyAsset => new StoryAsset(storyAsset))
                return storyAssets
            }),
            catchError(this.errorService.handleError),)
    }

    saveAsset (asset: any): Observable<Asset> {
        if (asset.id) {
            const url = `/assets/${asset.id}`
            return this.http
                .put(url, { asset }).pipe(
                map((data) => {
                    return new Asset(data)
                }),catchError(this.errorService.handleError),)
        } else {
            return this.createAsset(asset)
        }
    }

    getAssetStories (assetId: number): Observable<any> {
        const url = `/assets/${assetId}/stories`
        return this.http
            .get(url).pipe(
            map((data: any) => {
                return data
            }),
            catchError(this.errorService.handleError),)
    }

    getAsset (assetId: number): Observable<Asset> {
        const url = `/assets/${assetId}`
        return this.http
            .get<Asset>(url).pipe(
            map((data: any) => {
                return new Asset(data)
            }),
            catchError(this.errorService.handleError),)
    }

    getAssets (): Observable<Asset> {
        const url = `/assets`
        return this.http
            .get(url).pipe(
            map((data: any) => {
                const assets = data.map(asset => new Asset(asset))
                return assets
            }),
            catchError(this.errorService.handleError),)
    }

    deleteAsset (assetId): Observable<Asset> {
        const url = `/assets/${assetId}`
        return this.http
            .delete<Asset>(url).pipe(
            catchError(this.errorService.handleError))
    }
}
