
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ErrorService } from '../error/error.service';

@Injectable()
export class SubscriptionService {
    constructor (
        private http: HttpClient,
        private errorService: ErrorService
    ) {}

    changeSubscription (subscription: any): Observable<any> {
        const url = `/subscriptions/change`
        return this.http
            .put(url, subscription).pipe(
            map((response: HttpResponse<any>) => {
                return response || {}
            }),catchError(this.errorService.handleError),)
    }

    cancelSubscription (): Observable<any> {
        const url = `/subscriptions/cancel`
        return this.http
            .put(url, { }).pipe(
            map((response: HttpResponse<any>) => {
                return response || {}
            }),catchError(this.errorService.handleError),)
    }
}
