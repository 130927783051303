import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

@Injectable()
export class BarCodeService {

  private validation = /^\d{12,13}$/

  constructor () {}

  toInt (numString) {
    return parseInt(numString, 10)
  }

  isOdd (num) {
    return ( num % 2 ) === 1
  }

  calculateChecksum (gtin) {
    gtin = parseInt(gtin, 10).toString()
    const chunks = gtin.split('').map(this.toInt).reverse()
    let checksum = 0
    chunks.shift()
    chunks.forEach((num, index) => {
      checksum += this.isOdd(index) ? num : num * 3
    })
    checksum %= 10
    checksum = ( checksum === 0 ) ? 0 : ( 10 - checksum )
    return checksum
  }

  validate (input, callback) {

    const output = {
      barcode: input,
      isValid: false,
      error: 'Product Barcode is invalid.'
    }

    if ( !input || !input.length || input.length === 0 ) {
      return callback(output)
    }

    if ( input.length !== 12 && input.length !== 13 ) {
      output.error = 'Product Barcode length is invalid, barcode must be a 12 or 13 digit number.'
      return callback(output)
    }

    if ( this.validation.exec(input) === null ) {
      output.error = 'Product Barcode format is invalid, barcode must only contain digits.'
      return callback(output)
    }

    const checksum = parseInt(input.substring(input.length - 1), 10),
          calcChecksum = this.calculateChecksum(input)

    if ( checksum === calcChecksum ) {
      output.error = null
      output.isValid = true
    } else {
      output.error = 'Product Barcode checksum failed.'
    }

    callback(output)
  }

}
