import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ButtonsComponent } from './buttons.component';
import { StoryService, StoryPublishService } from '../../services';
import { TutorialTooltipModule } from '../tutorial-tooltip/tutorial-tooltip.module'

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        TutorialTooltipModule
    ],
    declarations: [ ButtonsComponent ],
    exports: [ ButtonsComponent ],
    providers: [ StoryService, StoryPublishService ]
})
export class ButtonsModule {}
