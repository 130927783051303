import { transparent, placeholder } from '../../helpers';

import { VimeoService } from '../../services/vimeo/vimeo.service';

import { environment } from '../../../../environments/environment';

const marker = 'https://s3.amazonaws.com/lookin-to/into-map-icon.png'

export class Card {
    id?: number
    position: number
    story_id: number
    card_type: string

    static build (data: any = {}) {
        switch (data.card_type) {
            case 'image_card':
                return new ImageCard(data)
            case 'video_card':
                return new VideoCard(data)
            case 'map_card':
                return new MapCard(data)
            default:
                throw new Error(`Unknown card type: ${data.card_type}`)
        }
    }

    constructor (data: any = {}) {
        Object.assign(this, data)
    }
}

export class ImageCard extends Card {
    title?: string
    subtitle?: string
    description?: string

    asset_id?: number
    asset_url?: string
    asset_widget_url?: string
    asset_lowres_url?: string
    card_image_url_local?: string
    // XXX not returned by API?
    asset_thumbnail_url?: string
    // XXX not sure if this every worked. replacing with card_image_url_local
    // card_image_thumbnail_url_local?: string

    constructor (data: any = {}) {
        super(data)
        this.card_type = 'image_card'
    }

    cardImage (type: string) {
        return this.card_image_url_local || this.asset_url || placeholder(type)
    }

    cardLowres () {
        return this.card_image_url_local || this.asset_lowres_url || this.asset_url || transparent
    }

    cardThumbnail (type: string) {
        // return this.card_image_thumbnail_url_local || this.asset_lowres_url
        return this.card_image_url_local || this.asset_lowres_url
            || this.asset_thumbnail_url || this.asset_url || placeholder(type)
    }
}

export class VideoCard extends Card {
    video_url: string
    preview_url?: string

    cover_image_url_local?: string
    cover_image_url?: string
    // XXX not returend by API?
    // cover_image_lowres_url: string
    // cover_image_thumbnail_url: string

    thumbnail_image_asset_id?: number
    thumbnail_image_asset_url?: string
    thumbnail_image_asset_lowres_url?: string
    thumbnail_image_asset_widget_url?: string
    thumbnail_image_url_local?: string

    display_thumbnail_image?: boolean

    constructor (data: any = {}) {
        super(data)
        this.card_type = 'video_card'
        this.preview_url = this.video_url
    }

    cardImage (type: string) {
        if (this.display_thumbnail_image) {
            return this.thumbnail_image_url_local || this.thumbnail_image_asset_url
                || this.cover_image_url_local || this.cover_image_url
                || placeholder(type)
        } else {
            return this.cover_image_url || placeholder(type)
        }
    }

    cardLowres () {
        if (this.display_thumbnail_image) {
            return this.thumbnail_image_url_local || this.thumbnail_image_asset_lowres_url
                || this.thumbnail_image_asset_url
                || this.cover_image_url_local || this.cover_image_url
                || transparent
        } else {
            return this.cover_image_url || transparent
        }
    }

    cardThumbnail (type: string) {
        if (this.display_thumbnail_image) {
            return this.thumbnail_image_url_local || this.thumbnail_image_asset_lowres_url
                || this.thumbnail_image_asset_url || this.cover_image_url_local || this.cover_image_url
                || placeholder(type)
        } else {
            return this.cover_image_url || placeholder(type)
            // return this.cover_image_thumbnail_url || this.cover_image_url || placeholder(type)
        }
    }
}

export class MapCard extends Card {
    title?: string
    subtitle?: string
    location?: string
    zoom?: string
    marker = marker
    latitude?: string
    longitude?: string
    latitude_local?: string
    longitude_local?: string
    cover_image_url?: string
    cover_image_url_local?: string
    cover_image_lowres_url?: string
    cover_image_thumbnail_url?: string

    constructor (data: any = {}) {
        super(data)
        this.card_type = 'map_card'
        this.zoom = data.zoom || 6
        this.latitude = ''
        this.longitude = ''

        if (data.latitude || data.latitude === 0) {
            this.latitude = data.latitude.toString()
        }

        if (data.longitude || data.longitude === 0) {
            this.longitude = data.longitude.toString()
        }

        this.latitude_local = this.latitude
        this.longitude_local = this.longitude
    }

    cardImage (type: string) {
        return this.cover_image_url || placeholder(type)
    }

    cardLowres () {
        return this.cover_image_url || transparent
    }

    cardThumbnail (type: string) {
        if (this.latitude_local.length < 1 || this.longitude_local.length < 1 ) {
            return placeholder(type)
        }

        return 'https://maps.googleapis.com/maps/api/staticmap' + '?'
            + 'center=' + this.latitude_local + ',' + this.longitude_local + '&'
            + 'key=' + environment.config['GOOGLE_API_KEY'] + '&'
            + 'size=110x160' + '&'
            + 'zoom=6'
    }
}
