
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';




import { User } from '../../models/user/user.model';
import { ErrorService } from '../error/error.service';

@Injectable()
export class UserService {
  constructor (
    private http: HttpClient,
    private router: Router,
    private errorService: ErrorService
  ) {}

  createUser (user: any): Observable<any> {
    const url = `/users/create`
    return this.http
      .post<User>(url, { user }).pipe(
      map(data => new User(data)),
      catchError((response: any) => {
        if (response && response.status && response.status === 400) {
          return observableThrowError(response)
        } else {
          return this.errorService.handleError(response)
        }
      }),)
  }

  getUser (userId: number): Observable<any> {
    const url = `/users/${userId}`
    return this.http
      .get<User>(url).pipe(
      map((data: any) => {
        return new User(data)
      }),
      catchError(this.errorService.handleError),)
  }

  getUsers (): Observable<any> {
    const url = `/users`
    return this.http
      .get(url).pipe(
      map((data: any) => {
        data.company_admins = data.company_admins.map(user => new User(user))
        data.contributors = data.contributors.map(user => new User(user))
        data.editors = data.editors.map(user => new User(user))
        data.publishers = data.publishers.map(user => new User(user))
        return data
      }),
      catchError(this.errorService.handleError),)
  }

  saveUser (user: User): Observable<User> {
    const url = `/users/${user.id}`
    return this.http
      .put<User>(url, { user }).pipe(
      map((data) => {
        return new User(data)
      }),
      catchError(this.errorService.handleError),)
  }

  deleteUser (user: User): Observable<any> {
    const url = `/users/${user.id}`
    return this.http
      .delete<User>(url).pipe(
      catchError(this.errorService.handleError))
  }
}
