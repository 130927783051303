import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { UserRegistrationHintComponent } from './user-registration-hint.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    declarations: [
        UserRegistrationHintComponent
    ],
    exports: [
        UserRegistrationHintComponent
    ]
})
export class UserRegistrationHintModule {}
