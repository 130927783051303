
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';



import { PagedInvoices } from '../../models/invoice/paged-invoices.model';
import { ErrorService } from '../error/error.service';

@Injectable()
export class InvoiceService {
    constructor (
        private http: HttpClient,
        private errorService: ErrorService
    ) {}

    getInvoices (lastPageId: string): Observable<PagedInvoices> {
        const url = `/subscriptions/invoices?limit=10` + (lastPageId ? '&last_page_id=' + lastPageId : '')

        return this.http
            .get<PagedInvoices>(url).pipe(
            map((data) => {
                return data
            }),catchError(this.errorService.handleError),)
    }
}
