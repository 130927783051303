const blank = 'assets/svgs/img-placeholder-TYPE.svg',
      transparent = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

import { Logo } from '../logo/logo.model';
import { Subscription } from '../subscription/subscription.model';
import { CompanySummary } from '../companySummary/companySummary.model';

export class Company {
  id: number
  name: string
  organization_name: string
  company_logo?: Logo
  plan: string
  locked: boolean
  active: boolean
  cancelled: boolean
  trial_start_at: Date
  trial_end_at: Date
  in_trial_period: boolean
  payment_details: boolean
  grace_period_end_at: Date
  next_subscription_start_at: Date
  next_subscription_end_at: Date
  created_at: Date
  updated_at: Date
  subscription: Subscription
  summary: CompanySummary
  coupon: string

  constructor(data: any = {}) {
    Object.assign(this, data)
    this.company_logo = new Logo(this.company_logo)
    this.subscription = new Subscription(this.subscription)
    this.summary = new CompanySummary(this.summary)
  }

  badgeImage () {
    return this.company_logo.logo_image_url_local ||
           this.company_logo.logo_image_url ||
           transparent
  }
}
