export class Plan {
  plan: string
  price: number
  published_story_limit: number
  user_limit: number

  constructor(data: any = {}) {
    Object.assign(this, data)
  }
}
