import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxFileDropModule } from "ngx-file-drop";

import { ImageUploadComponent } from "./image-upload.component";
import { ExifService } from "../../services/exif/exif.service";

@NgModule({
  imports: [CommonModule, RouterModule, NgxFileDropModule, NgbModule],
  declarations: [ImageUploadComponent],
  exports: [ImageUploadComponent],
  providers: [ExifService],
})
export class ImageUploadModule {}
