
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable ,  Subject } from 'rxjs';




import { environment } from '../../../../environments/environment';

import { Category, Subcategory } from '../../models/category/category.model';
import { AuthService } from '../auth/auth.service';
import { ErrorService } from '../error/error.service';
import { AddNotification } from '../../helpers/notification.helpers';

const UNASSIGNED_CATEGORY_NAME = 'Unassigned'

@Injectable()
export class CategoryService {
    categories: Category[]
    categories$

    uncategorizedCategoryId: number
    uncategorizedSubcategoryId: number

    constructor (
        private router: Router,
        private http: HttpClient,
        private errorService: ErrorService
    ) {
        this.categories$ = new Subject<any>()
    }

    getCategories (): Observable<any> {
        const url = `/categories`

        return this.http
            .get<any>(url, { observe: 'response' }).pipe(
            map((response: HttpResponse<any>) => {
                this.categories = response.body.map(item => new Category(item))

                const uncategorizedSubcategory = this.categories.find(
                    category => category.parent_id !== null && category.name === UNASSIGNED_CATEGORY_NAME
                )

                this.uncategorizedCategoryId = uncategorizedSubcategory.parent_id
                this.uncategorizedSubcategoryId = uncategorizedSubcategory.id

                this.categories$.next(this.categories)

                return {
                    categories: this.categories
                }
            }),catchError(this.errorService.handleError),)
    }

    // XXX not in use
    // getCategory (id: number): Observable<Category> {
    //     const url = `/categories/${id}`
    //     return this.http
    //         .get<Category>(url)
    //         .map((data) => {
    //             return new Category(data)
    //         }).catch(this.errorService.handleError)
    // }

    // XXX not in use
    // getSubcategory (id: number): Observable<Subcategory> {
    //     const url = `/categories/${id}`
    //     return this.http
    //         .get<Subcategory>(url)
    //         .map((data) => {
    //             return new Subcategory(data)
    //         }).catch(this.errorService.handleError)
    // }

    createCategory (category: Category): Observable<Category> {
        const url = `/categories`
        return this.http
            .post<Category>(url, { category }).pipe(
            map((data) => {
                return new Category(data)
            }),catchError(this.errorService.handleError),)
    }

    saveCategory (category: Category): Observable<Category> {
        if (category.id) {
            const url = `/categories/${category.id}`
            return this.http
                .put<Category>(url, { category }).pipe(
                map((data) => {
                    return new Category(data)
                }),catchError(this.errorService.handleError),)
        } else {
            return this.createCategory(category)
        }
    }

    deleteCategory (category: Category): Observable<Category> {
        const url = `/categories/${category.id}`
        return this.http
            .delete<Category>(url).pipe(
            catchError(this.errorService.handleError))
    }
}
