
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';






import { environment } from '../../../../environments/environment';

import { Story } from '../../models/story/story.model';
import { ErrorService } from '../error/error.service';
import { S3UploadService } from '../s3/s3.service';
import { AddNotification } from '../../helpers/notification.helpers';

@Injectable()
export class AnalyticsService {

  constructor (private router: Router,
               private s3: S3UploadService,
               private http: HttpClient,
               private errorService: ErrorService) {}

  fetchAnalytics (id: number): Observable<any> {
    const url =
      `/stories/${id}/analytics`
    return this.http
      .get<Story>(url, {}).pipe(
      map((data) => {
        return new Story(data)
      }),catchError(this.errorService.handleError),)
  }

}
