import { placeholder, transparent } from '../../helpers';
import { Address } from '../address/address.model';
import { BillingAddress } from '../address/billing-address.model';
import { Company } from '../company/company.model';
import { UserPreferences } from './user-preferences.model';

export class User {
    id: number
    first_name: string
    last_name: string
    email: string
    phone?: string
    password: string
    password_confirmation: string
    role: string
    stripe_token: string
    company?: Company
    company_name: string
    signup_code?: string
    enabled: boolean
    confirmed?: boolean
    profile_image_url?: string
    profile_image_url_local?: string
    profile_image_low_res_url?: string
    billing_address?: BillingAddress
    profile_address?: Address
    preferences?: UserPreferences
    story_assets?: any
    asset_count?: number
    story_team_count?: number

    constructor (data: any = {}) {
        Object.assign(this, data)

        this.company = new Company(this.company)
        this.billing_address = new BillingAddress(this.billing_address)
        this.profile_address = new Address(this.profile_address)
        this.preferences = new UserPreferences(this.preferences)
    }

    profileImage (type: string) {
        return this.profile_image_url_local || this.profile_image_url || placeholder(type)
    }

    hasProfileImageImage () {
        return this.profile_image_url_local || this.profile_image_url
    }

    profileImageLowres () {
        return this.profile_image_url_local || this.profile_image_low_res_url
            || this.profile_image_url || transparent
    }
}
