import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import {
    SharedService
} from '../../services';

@Component({
    selector: 'app-user-invite-item',
    templateUrl: './user-invite-item.component.html',
    styleUrls: ['./user-invite-item.component.scss']
})
export class UserInviteItemComponent {
    @Input() mode: string

    constructor (
        private router: Router,
        private sharedService: SharedService
    ) {}

    onInviteUserClick (event) {
        event.preventDefault()
        event.stopPropagation()

        if (this.mode === 'publisher') {
            this.sharedService.triggerModal(
                'create_new_team',
                (choice) => {
                    if (choice && choice.action === 'confirm') {
                        this.navigateToUserInvitePage()
                    }
                }
            )
        } else {
            this.navigateToUserInvitePage()
        }
    }

    navigateToUserInvitePage () {
        this.router.navigate(
            [ '/', 'user', 'invite' ],
            { queryParams: { role: this.mode } }
        )
    }

    getLabelRole () {
        let label;
        switch (this.mode) {
            case 'company_admin':
                label = 'Storymaster'
                break;
            case 'publisher':
                label = 'Publisher'
                break;
            case 'editor':
                label = 'Editor'
                break;
            case 'contributor':
                label = 'StorySource'
                break;
        }
        return label || ''
    }
}
