const blank = 'assets/svgs/img-placeholder-TYPE.svg',
      transparent = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

import { environment } from '../../../../environments/environment';

export class Logo {

    id?: number
    name?: string
    logo_image_url?: string
    logo_image_url_local?: string
    logo_image_lowres_url?: string
    logo_image_widget_url?: string

    constructor (data: any = {}) {
        Object.assign(this, data)
    }

    badgeImage (type: string = 'square') {
        return this.logo_image_url_local ||
               this.logo_image_url ||
               blank.replace('TYPE', type)
    }

    badgeLowres () {
        return this.logo_image_url_local ||
               this.logo_image_lowres_url ||
               this.logo_image_url ||
               transparent
    }

    badgeThumbnail () {
        return this.logo_image_url_local ||
               this.logo_image_url ||
               transparent
    }

}
