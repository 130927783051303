import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class NewStoryService {
    notify: Subject<any>

    // dirtyCard = false
    token: string

    constructor () {
        this.notify = new Subject()
    }

    // markAsDirty () {
    //     this.dirtyCard = true
    // }

    // markAsClean () {
    //     this.dirtyCard = false
    // }

    fire () {
        // this.notify.next(this.dirtyCard)
        this.notify.next()
    }
}
