import * as moment from 'moment';

import { User, Team } from '../models';

import { getUserTeamIds } from './storytelling-network.helpers'

// const isPlanActive = (user: User): boolean => {
//     return (
//         user.company.in_trial_period === true
//         || moment().diff(user.company.subscription.end_at, 'seconds') < 0
//     )
// }

export const UserHasAccess = (user: User, teams: Team[], url: string): boolean => {
    const pageAccess = {
        '/account': [],
        '/account/overview': [],
        '/account/plans-and-pricing': [],
        '/account/billing': [],
        '/analytics': [],
        '/categories': [],
        '/library': [ 'publisher', 'editor' ],
        '/logos': [ 'publisher' ],
        '/product': [ 'publisher' ],
        '/products': [ 'publisher' ],
        '/related': [],
        '/share': [ 'publisher' ],
        '/story': [ 'publisher', 'editor' ],
        '/story/new': [ 'publisher', 'editor' ],
        '/stories': [ 'publisher', 'editor' ],
        '/teams': [ 'publisher', 'editor' ],
        '/team/new': [ 'publisher' ],
        '/team/\\d+/add-members': [ 'publisher', 'editor' ],
        '/users': [],
    }

    const mustBeOnATeamPageAccess = {
        '/library': [ 'publisher', 'editor' ],
        '/stories': [ 'publisher', 'editor' ],
        '/story': [ 'publisher', 'editor' ],
        '/story/new': [ 'publisher', 'editor' ],
        '/teams': [ 'editor' ],
    }

    // const mustHaveActivePlanPageAccess = [
    //     '/stories',
    //     '/story/new',
    //     '/library',
    //     '/users',
    //     '/teams',
    //     '/categories',
    // ]

    if (user.role === 'company_admin') {
        // XXX disabling this, though we may want to use it
        // const planAccessOk = (
        //     !mustHaveActivePlanPageAccess.includes(url)
        //     || isPlanActive(user)
        // )

        return true
    } else {
        let urlMatch = Object.keys(pageAccess).find(
            pageAccessUrl => url.match(pageAccessUrl + '$') !== null
        )

        const pageAccessOk = (
            !urlMatch
            || pageAccess[urlMatch].includes(user.role)
        )

        urlMatch = Object.keys(mustBeOnATeamPageAccess).find(
            pageAccessUrl => url.match(pageAccessUrl + '$') !== null
        )

        const teamAccessOk = (
            !Object.keys(mustBeOnATeamPageAccess).includes(urlMatch)
            || !mustBeOnATeamPageAccess[urlMatch].includes(user.role)
            || getUserTeamIds(user, teams).length > 0
        )

        // XXX disabling this, though we may want to use it
        // const planAccessOk = (
        //     !mustHaveActivePlanPageAccess.includes(url)
        //     || isPlanActive(user)
        // )

        return pageAccessOk && teamAccessOk
    }
}
