import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-video-modal',
    template: `
        <div class="modal-header">
            <span class="header-text">{{ name }}</span>
            <button
                aria-label="Close"
                class="close close-modal"
                type="button"
                (click)="activeModal.dismiss('close_click')">
                <span aria-hidden="true" class="icon-Into-close"></span>
            </button>
        </div>
        <div class="modal-body">
            <video controls>
                <source src="{{ url }}" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
    `,
    styles: [
        '.modal-header { color: black; }',
        '.close-modal { font-size: .9rem; }',
        'video { width: 100%; }',
    ]
})
export class VideoModalComponent {
    @Input() url
    @Input() name

    constructor(public activeModal: NgbActiveModal) {}
}
