import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../services';

import { DisplayDate } from '../../helpers/date.helpers';
import { AddNotification } from '../../helpers/notification.helpers';

@Component({
    selector: 'app-alert-plans',
    templateUrl: './alert-plans.component.html',
    styleUrls: ['./alert-plans.component.scss']
})
export class AlertPlansComponent implements OnInit {
    alert
    user
    errorMessage

    constructor (
        private route: ActivatedRoute,
        private authService: AuthService
    ) {
        this.user = this.authService.user

        this.authService.user$.subscribe((user) => {
            if (!user.status) {
                this.user = user
                this.generateError()
            }
        })
    }

    ngOnInit () {
        this.generateError()
    }

    generateError () {
        this.route.url.subscribe((url) => {
            if (this.user && this.user.company.in_trial_period) {
                this.errorMessage = 'You are a free-trial user and trial period ends on '
                    + DisplayDate(String(this.user.company.trial_end_at)) + '.'
            }

            if (
                this.user
                && this.user.company.payment_details
                && this.user.company.subscription.error_count
            ) {
                this.errorMessage = 'Your payment has failed. Please update your payment method.'
            }
        })
    }
}
