import { Observable ,  Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class SharedService {
    public modalSource = new Subject<any>()
    public modalEmitted = this.modalSource.asObservable()

    public triggerModal (type: string, callback: any, args?: any) {
        this.modalSource.next({ type, callback, args: args || {} })
    }

    public recieveModalChanges () {
        return this.modalEmitted
    }
}
