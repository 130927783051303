
import {throwError as observableThrowError,  Observable ,  of } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';






import { environment } from '../../../../environments/environment';

import { ErrorService } from '../error/error.service';
import { AddNotification } from '../../helpers/notification.helpers';

@Injectable()
export class YoutubeService {

    constructor (private router: Router,
                 private http: HttpClient,
                 private errorService: ErrorService) {}

    parseUrl (url) {
        let parsed
        try {
            parsed = new URL(url)
        } catch (error) {
            return
        }
        parsed['naked'] =
            parsed.origin +
            parsed.pathname +
            parsed.search
        return parsed
    }

    getParams (url) {
        const regex = /[?&]([^=#]+)=([^&#]*)/g,
              params = {};
        let match;
        while (match = regex.exec(url)) {
            params[match[1]] = match[2];
        }
        return params;
    }

    extractId (url) {
        const params = this.getParams(url);
        if ( params && params['v'] ) {
            return params['v'];
        }
        if ( url['search'].indexOf('?v=') > -1 ) {
            return url['search'].replace('?v=', '')
        }
        if ( url['pathname'].indexOf('/v/') > -1 ) {
            return url['pathname'].replace('/v/', '')
        }
        if ( url['pathname'].indexOf('/embed/') > -1 ) {
            return url['pathname'].replace('/embed/', '')
        }
        if ( url['hostname'].indexOf('youtu.be') > -1 ) {
            return url['pathname'].replace('/', '')
        }
        if ( url['pathname'].indexOf('/oembed') > -1 ) {
            const parsedUrl =
                this.parseUrl(url.searchParams.get('url'))
            return this.extractId(parsedUrl)
        }
        return
    }

    getYoutubeData (url) {
       const parsedUrl = this.parseUrl(url),
             id = this.extractId(parsedUrl),
             apikey = environment.config['GOOGLE_API_KEY'],
             youtubeUrl = `https://www.googleapis.com/youtube/v3/videos` +
                          `?key=${apikey}` +
                          `&part=snippet` +
                          `&id=${id}`
        if ( !id ) {
            return observableThrowError('Error')
        }
        return this.http
          .get(youtubeUrl).pipe(
          map((data) => {
            return data
          }),catchError(this.errorService.handleError),)
    }

}
