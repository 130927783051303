
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';



import { AddNotification } from '../../helpers/notification.helpers';

const TryMessage = (error, path1, path2: string = null, path3: string = null) => {
    if ( path1 && path2 && path3 ) {
        try {
            return error[path1][path2][path3]
        } catch (e) {
            return false
        }
    } else if ( path1 && path2 ) {
        try {
            return error[path1][path2]
        } catch (e) {
            return false
        }
    } else if ( path1 ) {
        try {
            return error[path1]
        } catch (e) {
            return false
        }
    } else {
        return false
    }
}

@Injectable()
export class ErrorService {
    constructor (private router: Router) {}

    handleError (response: HttpErrorResponse) {
        let errorMessage = 'Server error'

        if (typeof response === 'object') {
            // new error handling as of 2019-10-19
            // this method should work 99% of the time, but the legacy error handling
            // will still run in cases where this fails
            if (response.error && Array.isArray(response.error.error) && response.error.error.length > 0) {
                errorMessage = response.error.error.join(', ')
            } else {
                console.log('legacy error message caught in ErrorService')

                const messages = {
                    statusText: TryMessage(response, 'statusText'),
                    error: TryMessage(response, 'error'),
                    errors: TryMessage(response, 'errors'),
                    error_error: TryMessage(response, 'error', 'error'),
                    errors_errors: TryMessage(response, 'errors', 'errors'),
                    error_error_message: TryMessage(response, 'error', 'error_message'),
                    error_error_message_m: TryMessage(response, 'error', 'error', 'm')
                }
                const high = 0

                for (const index in messages) {
                    if (messages[index] && typeof messages[index] === 'string' && messages[index].length > high) {
                        errorMessage = messages[index] || 'Unknown error'
                    }
                }
            }

        }

        if (response.status !== 500 && response.status !== 401) {
            AddNotification({
                title: 'Error',
                text: errorMessage
            }, 3000)

        } else if (response.status === 401) {
            AddNotification({
                title: 'Error',
                text: errorMessage
            }, 3000)

            localStorage.removeItem('email')
            localStorage.removeItem('token')

            if (this.router && this.router.navigate) {
                this.router.navigate([ '/', 'logout' ])
            } else {
                window.location.href = '/#/logout'
            }
        }

        return observableThrowError(errorMessage)
    }
}
