import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-user-registration-hint',
    templateUrl: './user-registration-hint.component.html',
})
export class UserRegistrationHintComponent {
    @Input() role: string
    @Input() class: string
}
