import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { environment } from '../../../../environments/environment';

const BASE_URL = environment.config['API_PROTOCOL'] + '://'
    + environment.config['API_URL'] + '/'
    + environment.config['API_PATH'] + '/'
    + environment.config['API_VERSION']

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor (private injector: Injector) {}

    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // this prevent cyclic dependencies
        const auth = this.injector.get(AuthService)

        if (req.url.indexOf('http') === 0) {
            return next.handle(req)
        }

        if (req.url.indexOf('i18n') > -1) {
            return next.handle(req)
        }

        return next.handle(req.clone({
            url: `${BASE_URL}${req.url}`,
            headers: auth.getHeaders()
        }))
    }
}
