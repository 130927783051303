import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
    AuthGuard,
    TeamGuard,
    HasTeamGuard,
    LoginGuard,
    InactivePlanGuard,
    UserRoleGuard,
    AuthService,
    ApiInterceptor,
    ErrorService,
    SharedService,
    S3UploadService,
    SharedPipesModule,
    NotificationComponent,
    AnalyticsService,
    UserService,
    TeamService,
    CategoryService
} from './shared';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json')
}

@NgModule({
    declarations: [
        AppComponent,
        NotificationComponent
    ],
    imports: [
        NgbModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        AppRoutingModule,
        SharedPipesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: ( HttpLoaderFactory ),
                deps: [ HttpClient ]
            }
        })
    ],
    providers: [
        AuthGuard,
        TeamGuard,
        HasTeamGuard,
        LoginGuard,
        InactivePlanGuard,
        UserRoleGuard,
        AuthService,
        ErrorService,
        AnalyticsService,
        SharedService,
        UserService,
        TeamService,
        CategoryService,
        S3UploadService, {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true
        }
    ],
    bootstrap: [ AppComponent, NotificationComponent ]
})
export class AppModule {}
