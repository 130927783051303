
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Plan } from '../../models/plan/plan.model';
import { ErrorService } from '../error/error.service';




@Injectable()
export class PlanService {

  constructor (
    private http: HttpClient,
    private errorService: ErrorService
  ) {}

  fetchPlans (): Observable<Array<Plan>> {
    const url = `/plans`
    return this.http
      .get<Array<Plan>>(url).pipe(
      map((data) => {
        return data.map((item) => new Plan(item))
      }),catchError(this.errorService.handleError),)
  }
}
