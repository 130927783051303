import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { VideoModalComponent } from './video-modal.component';

@NgModule({
    imports: [
        NgbModule
    ],
    declarations: [
        VideoModalComponent
    ],
    exports: [
        VideoModalComponent
    ]
})
export class VideoModalModule {}
