import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { NOTIFICATIONS, RemoveNotification } from '../../helpers/notification.helpers';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: [ './notification.component.scss' ]
})
export class NotificationComponent {

    notifications = NOTIFICATIONS

    constructor () {}

    removeNotification (notification) {
        RemoveNotification(notification)
    }

}
