export class Invoice {
  number: string
  date: Date
  url: string
  pdf: string
  status: string

  constructor(data: any = {}) {
    Object.assign(this, data)
  }
}
