
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';

import { User } from '../../models/user/user.model';
import { Company } from '../../models/company/company.model';
import { AuthService } from '../auth/auth.service';
import { ErrorService } from '../error/error.service';




@Injectable()
export class CompanyService {
  user: User

  constructor (
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private errorService: ErrorService
  ) {}

  // XXX dead code - this service is never called on the frontend
  // leaving it here though in case we use it in the future
  saveCompany (company: Company): Observable<Company> {
    const url = `/companies/${company.id}`
    return this.http
      .put<Company>(url, { company }).pipe(
      map((data) => {
        const newCompany = new Company(data)

        this.authService.current().subscribe()

        return newCompany
      }),catchError(this.errorService.handleError),)
  }
}
