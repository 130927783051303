export class Notification {

  title: string
  text: string
  size?: string
  hideConfirm: boolean

  constructor (data: any = {}) {
    Object.assign(this, data)
  }

}
