
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Card } from '../../models/card/card.model';
import { ErrorService } from '../error/error.service';




@Injectable()
export class CardService {
    constructor (
        private http: HttpClient,
        private router: Router,
        private errorService: ErrorService
    ) {}

    fetchCards (storyId: number): Observable<Array<Card>> {
        const url = `/stories/${storyId}/cards`
        return this.http
            .get<Array<Card>>(url).pipe(
            map((data) => {
                return data.map((item) => Card.build(item));
            }),catchError(this.errorService.handleError),)
    }

    addCard (card: Card): Observable<Card> {
        const url = `/stories/${card.story_id}/cards`;
        return this.http
            .post<Card>(url, { card, card_type: card.card_type }).pipe(
            map((data) => {
                return Card.build(data)
            }),catchError(this.errorService.handleError),)
    }

    saveCard (card: Card): Observable<Card> {
        if (card.id) {
            const url = `/cards/${card.id}?card_type=${card.card_type}`
            return this.http
                .put<Card>(url, { card }).pipe(
                map((data) => {
                    return Card.build(data)
                }),catchError(this.errorService.handleError),)
        } else {
            return this.addCard(card)
        }
    }

    deleteCard (card: Card): Observable<Card> {
        const url = `/cards/${card.id}?card_type=${card.card_type}`
        return this.http
            .delete<Card>(url).pipe(
            catchError(this.errorService.handleError))
    }

    // addStoryAssetCard (card: Card, storyAssetId: number): Observable<Card> {
    //     const url = `/stories/${card.story_id}/cards?card_type=${card.card_type}&story_asset_id=${storyAssetId}`;
    //     return this.http
    //         .post<Card>(url, { card, card_type: card.card_type })
    //         .map((data) => {
    //             return Card.build(data)
    //         }).catch(this.errorService.handleError)
    // }
}
