
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';

import { Logo } from '../../models/logo/logo.model';
import { Product } from '../../models/product/product.model';
import { ErrorService } from '../error/error.service';






@Injectable()
export class LogoService {
  constructor (
    private http: HttpClient,
    private router: Router,
    private errorService: ErrorService
  ) {}

  generateQuery (data: any): string {
    return Object.keys(data).map((key) => {
      return [key, data[key]].map(encodeURIComponent).join('=')
    }).join('&')
  }

  buildPaginationQuery (query: any, pagination: any): string {
    if ( !pagination || pagination.perpage === 'all' ) {
      query['per_page'] = -1
    } else {
      query['per_page'] =
        pagination.perpage
      query['page'] =
        pagination.page
    }
    return query
  }

  getLogos (pagination?: any): Observable<any> {
    const query = {}
    this.buildPaginationQuery(query, pagination)
    const url = `/company_logos?` + this.generateQuery(query)
    return this.http
      .get<any>(url, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return {
          pages: response.headers.get('X-PAGINATION-PAGE-COUNT'),
          logos: response.body.map(item => new Logo(item))
        }
      }),catchError(this.errorService.handleError),)
  }

  saveLogo (logo: Logo): Observable<Logo> {
    const url = `/company_logos/${logo.id}`
    return this.http
      .put<Logo>(url, { company_logo: logo }).pipe(
      map((data) => {
        return new Logo(data)
      }),catchError(this.errorService.handleError),)
  }

  createLogo (logo: Logo): Observable<Logo> {
    const url = `/company_logos`
    return this.http
      .post<Logo>(url, { company_logo: logo }).pipe(
      map((data) => {
        return new Logo(data)
      }),catchError(this.errorService.handleError),)
  }

  deleteLogo (logo: Logo): Observable<Logo> {
    const url = `/company_logos/${logo.id}`
    return this.http
      .delete<Logo>(url).pipe(
      catchError(this.errorService.handleError))
  }
}
