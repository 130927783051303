import { Injectable } from '@angular/core';

declare var require;

const EXIF = require('exif-js_fixed');

@Injectable()
export class ExifService {
    public getOrientedImage (blob: Blob): Promise<Blob> {
        const result: Promise<Blob> = new Promise((resolve, reject) => {
            const image: any = document.createElement('img'),
                url = URL.createObjectURL(blob)

            image.onload = () => {
                EXIF.getData(image, () => {
                    const orientation = EXIF.getTag(image, 'Orientation'),
                        canvas: HTMLCanvasElement = document.createElement('canvas'),
                        ctx: CanvasRenderingContext2D = <CanvasRenderingContext2D> canvas.getContext('2d')

                    let cw = image.width,
                        ch = image.height,
                        cx = 0,
                        cy = 0,
                        deg = 0

                    switch (orientation) {
                        case 3:
                        case 4:
                            cx = -image.width
                            cy = -image.height
                            deg = 180
                            break
                        case 5:
                        case 6:
                            cw = image.height
                            ch = image.width
                            cy = -image.height
                            deg = 90
                            break
                        case 7:
                        case 8:
                            cw = image.height
                            ch = image.width
                            cx = -image.width
                            deg = 270
                            break
                        default:
                            break
                    }

                    canvas.width = cw
                    canvas.height = ch

                    if ([ 2, 4, 5, 7 ].indexOf(orientation) > -1) {
                        ctx.translate(cw, 0)
                        ctx.scale(-1, 1)
                    }

                    ctx.rotate(deg * Math.PI / 180)
                    ctx.drawImage(image, cx, cy)

                    canvas.toBlob(newBlob => {
                        resolve(newBlob)
                    }, blob.type, 0.5)
                })

                URL.revokeObjectURL(url)
            }

            image.src = url
        })

        return result
    }
}
