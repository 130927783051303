
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import {
  Router,
  Resolve,
  CanActivate,
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

import * as moment from 'moment';

import { AuthService } from './auth.service';
import { TeamService } from '../team/team.service';

import { User, Team } from '../../models';

import { getUserTeamIds } from '../../helpers'

@Injectable()
export class AuthGuard implements CanActivate {
  constructor (
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (this.authService.user && this.authService.user.id) {
      return true
    }

    const returnOn401Error = (
      state.url.startsWith('/story/new?token=')
        || state.url.match(/\/story\/[0-9]+\/assets\?user=[0-9]+/) ? true : false
    )

    return this.authService.current(returnOn401Error).pipe(
      map((response: HttpResponse<any>) => {
        if (returnOn401Error && response.status === 401) {
          this.authService.postLoginUrl = state.url
          return false
        } else {
          return true
        }
      }, (error: HttpResponse<any>) => {
        return false
      }))
  }
}

@Injectable()
export class TeamGuard implements CanActivate {
  constructor (
    private router: Router,
    private authService: AuthService,
    private teamService: TeamService
  ) {}

  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (this.teamService.teams.length > 0) {
      return true
    } else if (this.authService.user.role === 'contributor') {
      return true
    }

    return this.teamService.getTeams().pipe(
      map((response: HttpResponse<any>) => {
        return true
      }, (error: HttpResponse<any>) => {
        return false
      }),
      catchError((response: any) => {
        // 400: trial or plan expired users are unable to retrieve team data
        if (
          response && response.status && response.status === 400
        && response.error && response.error.error
        && (
          response.error.error === 'Please update your payment details.'
            || response.error.error === 'Exceptions::PaymentRequired'
              || response.error.error.m === 'Exceptions::PaymentRequired'
        )
        ) {
          return of(true)
                }
            }),)
    }
}

@Injectable()
export class LoginGuard implements CanActivate {
    user: User

    constructor (
        private router: Router,
        private authService: AuthService
    ) {}

    canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        if (!localStorage.getItem('email') || !localStorage.getItem('token')) {
            return true
        }

        if (this.router && this.router.navigate) {
            this.router.navigate([ '/' ])
        } else {
            window.location.href = '/#/'
        }

        return false
    }
}

@Injectable()
export class InactivePlanGuard implements CanActivate {
    constructor (
        private router: Router,
        private authService: AuthService
    ) {}

    checkRedirect (user: User) {
        if (!(
            user.company.in_trial_period === true
            || moment().diff(user.company.subscription.end_at, 'seconds') < 0
        )) {
            if (this.router && this.router.navigate) {
                this.router.navigate([ '/', 'account', 'plans-and-pricing' ])
            } else {
                window.location.href = '/#/account/plans-and-pricing'
            }

            return false
        } else {
            return true
        }
    }

    canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        if (this.authService.user && this.authService.user.id) {
            return this.checkRedirect(this.authService.user)
        }

        return this.authService.current().pipe(
            map((response: HttpResponse<any>) => {
                return this.checkRedirect(new User(response))
            }, (error: HttpResponse<any>) => {
                return false
            }))
    }
}

@Injectable()
export class UserRoleGuard implements CanActivate {
    constructor (
        private router: Router,
        private authService: AuthService
    ) {}

    verifyRoleAccess (user: User, route: ActivatedRouteSnapshot) {
        if (user.role === 'company_admin' || user.role === 'super_admin') {
            return true
        } else if (route.data.enabledRoles) {
            return route.data.enabledRoles.includes(user.role)
        } else {
            return false
        }
    }

    canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        if (this.authService.user && this.authService.user.id) {
            return this.verifyRoleAccess(this.authService.user, route)
        }

        return this.authService.current().pipe(
            map((response: HttpResponse<any>) => {
                return this.verifyRoleAccess(new User(response), route)
            }, (error: HttpResponse<any>) => {
                return false
            }))
    }
}

@Injectable()
export class HasTeamGuard implements CanActivate {
    constructor (
        private router: Router,
        private authService: AuthService,
        private teamService: TeamService
    ) {}

    canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        if (this.authService.user.role === 'company_admin') {
            return true
        } else if (this.authService.user.role === 'contributor') {
            return false
        } else {
            if (
                route.data.teamRequiredRoles
                && !route.data.teamRequiredRoles.includes(this.authService.user.role)
            ) {
                return true
            } else {
                return getUserTeamIds(this.authService.user, this.teamService.teams).length > 0
            }
        }
    }
}

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>
}

@Injectable()
export class ChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    canDeactivate(
        component: ComponentCanDeactivate, route: ActivatedRouteSnapshot
    ): boolean | Observable<boolean> {
        let message

        if (route.data && route.data.page === 'categories') {
            message = 'You have unsaved categories. '
                + 'A category must have at least one subcategory. '
                + 'Please add a subcategory to each new category and save the subcategory. '
                + '\n\nPress OK to abandon changes.'
        } else {
            message = 'You have unsaved changes.'
        }

        return (!component || component.canDeactivate()) ? true : confirm(message)
    }
}
