import { Pipe, PipeTransform } from '@angular/core';

import { DisplayDate } from '../helpers/date.helpers';

@Pipe({
    name: 'displayDate'
})
export class DisplayDatePipe implements PipeTransform {
    transform (date: string): string {
        return DisplayDate(date)
    }
}
