export class UserSummary {
  total: number
  admins: number
  publishers: number
  editors: number
  contributors: number

  constructor(data: any = {}) {
    Object.assign(this, data)
  }
}
