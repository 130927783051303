import { transparent, placeholder } from '../../helpers'

const comingSoonUrl = '/assets/images/testing/video-thumbnail-coming-soon.png'

export class Asset {
    id: number
    name: string
    description?: string
    asset_type: string
    author_name: string
    generated_name?: boolean

    // XXX
    // categories: Category[]
    categories: any
    tags?: any
    stories?: any
    created_at: Date
    updated_at: Date

    item_url?: string
    item_url_local?: string
    item_lowres_url?: string
    item_thumbnail_url?: string
    item_thumbnail_url_local?: string
    item_mpeg_url?: string

    constructor (data: any = {}) {
        Object.assign(this, data)

        if (!this.stories) {
            this.stories = []
        }

        if (!this.categories) {
            this.categories = []
        }

        // this.categories = this.children.map(category => {
        //     return new Category(subcategory)
        // })
    }

    assetImage (type: string) {
        return this.item_url_local || this.item_url || placeholder(type)
    }

    assetLowres () {
        if (this.asset_type === 'video') {
            return this.item_url_local || this.item_lowres_url || comingSoonUrl || transparent
        } else {
            return this.item_url_local || this.item_lowres_url || this.item_url || transparent
        }
    }

    assetThumbnail (type: string) {
        if (this.asset_type === 'video') {
            return this.item_thumbnail_url || comingSoonUrl
        } else {
            return this.item_thumbnail_url || this.item_url || placeholder(type)
        }
    }
}
