import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TeamItemComponent } from './team-item.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        TeamItemComponent
    ],
    exports: [
        TeamItemComponent
    ],
    providers: []
})
export class TeamItemModule {}
