import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tutorial-tooltip',
    templateUrl: './tutorial-tooltip.component.html',
})
export class TutorialTooltipComponent {
    @Input() context: string
    @Input() type: string
    @Input() floatingPosition: string
    @Input() iconPosition: string
    @Input() tooltipPlacement: string
    @Input() showHelpText: boolean
    @Input() howToText: string
    @Input() videoUrl: string

    constructor () {}
}
