import { Invoice } from './invoice.model';

export class PagedInvoices {
  page_id: string
  last_page_id: number
  has_more: boolean
  invoices: Array<Invoice>

  constructor(data: any = {}) {
    Object.assign(this, data)
  }
}
