
export class StoryTour {
  new = false
  imageCardTour = false
  videoCardTour = false
  mapCardTour = false
  previewFormTour = false
  step: number
  visible = true

  STEP_INACTIVE = 0
  STEP_DONE = 9999

  NEW_STORY_STEP_IMAGE_UPLOAD = 1
  NEW_STORY_STEP_TITLE = 2
  NEW_STORY_STEP_CATEGORY = 3
  NEW_STORY_STEP_SUBCATEGORY = 4
  NEW_STORY_STEP_LOGO = 5
  NEW_STORY_STEP_SAVE_COVER = 6
  NEW_STORY_STEP_ADD_CARD = 7

  IMAGE_CARD_STEP_IMAGE_UPLOAD = 100
  IMAGE_CARD_STEP_TITLE = 101
  IMAGE_CARD_STEP_TEXT = 102
  IMAGE_CARD_STEP_PREVIEW_TEXT = 103

  VIDEO_CARD_STEP_URL = 200
  VIDEO_CARD_STEP_PREVIEW_VIDEO = 201

  MAP_CARD_STEP_LOCATION = 300

  PREVIEW_FORM_STEP_PREVIEW_TEXT = 400

  constructor (data: any = {}) {
    Object.assign(this, data)

    this.step = this.STEP_INACTIVE

    if ( this.isNew() ) {
      this.step = this.NEW_STORY_STEP_IMAGE_UPLOAD
    }
  }

  isNew (): boolean {
    return this.new
  }

  isVisible (): boolean {
    return this.visible
  }

  // story tour

  isStoryTour (): boolean {
    /// return true # disabling any tooltips for now
    return false
  }

  isImageUploadStep (): boolean {
    return ( this.step === this.NEW_STORY_STEP_IMAGE_UPLOAD )
  }

  isTitleStep (): boolean {
    return ( this.step === this.NEW_STORY_STEP_TITLE )
  }

  // isCategoryStep (): boolean {
  //   return ( this.step === this.NEW_STORY_STEP_CATEGORY )
  // }

  // isSubcategoryStep (): boolean {
  //   return ( this.step === this.NEW_STORY_STEP_SUBCATEGORY )
  // }

  isLogoStep (): boolean {
    return ( this.step === this.NEW_STORY_STEP_LOGO )
  }

  isSaveCoverStep (): boolean {
    return ( this.step === this.NEW_STORY_STEP_SAVE_COVER )
  }

  isAddNextCardStep (): boolean {
    return ( this.step === this.NEW_STORY_STEP_ADD_CARD )
  }

  getImageUploadStepNumber (): number {
    return this.NEW_STORY_STEP_IMAGE_UPLOAD
  }

  getStoryTitleStepNumber (): number {
    return this.NEW_STORY_STEP_TITLE
  }

  getCategoryStepNumber (): number {
    return this.NEW_STORY_STEP_CATEGORY
  }

  getSubcategoryStepNumber (): number {
    return this.NEW_STORY_STEP_SUBCATEGORY
  }

  getLogoStepNumber (): number {
    return this.NEW_STORY_STEP_LOGO
  }

  getSaveCoverStepNumber (): number {
    return this.NEW_STORY_STEP_SAVE_COVER
  }

  getAddNextCardStepNumber (): number {
    return this.NEW_STORY_STEP_ADD_CARD
  }

  coverImageUploaded (): void {
    if ( this.isNew() ) {
      this.step = this.NEW_STORY_STEP_TITLE
    } else {
      this.step = this.IMAGE_CARD_STEP_TITLE
    }
  }

  titleUpdated (): void {
    if ( this.step > this.NEW_STORY_STEP_TITLE ) {
      return
    }

    this.step = this.NEW_STORY_STEP_CATEGORY
  }

  categoryUpdated (): void {
    if ( this.step > this.NEW_STORY_STEP_CATEGORY ) {
      return
    }

    this.step = this.NEW_STORY_STEP_SUBCATEGORY
  }

  subcategoryUpdated (): void {
    if ( this.step > this.NEW_STORY_STEP_SUBCATEGORY ) {
      return
    }

    this.step = this.NEW_STORY_STEP_LOGO
  }

  logoToggleActivated (): void {
    this.visible = false

    if ( this.step > this.NEW_STORY_STEP_LOGO ) {
      return
    }

    this.step = this.NEW_STORY_STEP_LOGO
  }

  logoToggleDeactivated (): void {
    this.visible = true

    if ( this.step > this.NEW_STORY_STEP_LOGO ) {
      return
    }

    this.step = this.NEW_STORY_STEP_LOGO
  }

  logoSelected (): void {
    if ( this.step > this.NEW_STORY_STEP_LOGO ) {
      return
    }

    this.step = this.NEW_STORY_STEP_SAVE_COVER
  }

  saveCoverButtonFocus (): void {
    if ( this.step > this.NEW_STORY_STEP_SAVE_COVER ) {
      return
    }

    this.step = this.NEW_STORY_STEP_SAVE_COVER
  }

  coverSaved (): void {
    this.step = this.NEW_STORY_STEP_ADD_CARD
  }

  cardSaved (): void {
    this.step = this.NEW_STORY_STEP_ADD_CARD
  }

  cardsEmpty (): void {
    this.step = this.NEW_STORY_STEP_ADD_CARD
  }

  // image card tour

  isImageCardTourImageUploadStep (): boolean {
    return ( this.step === this.IMAGE_CARD_STEP_IMAGE_UPLOAD )
  }

  isImageCardTourTitleStep (): boolean {
    return ( this.step === this.IMAGE_CARD_STEP_TITLE )
  }

  isImageCardTourTextStep (): boolean {
    return ( this.step === this.IMAGE_CARD_STEP_TEXT )
  }

  isImageCardTourPreviewTextStep (): boolean {
    return ( this.step === this.IMAGE_CARD_STEP_PREVIEW_TEXT )
  }

  imageCardTourCardAdded (): void {
    this.step = this.IMAGE_CARD_STEP_IMAGE_UPLOAD
  }

  imageCardTourTitleUpdated (): void {
    this.step = this.IMAGE_CARD_STEP_TEXT
  }

  imageCardTourTextUpdated (): void {
    this.step = this.IMAGE_CARD_STEP_PREVIEW_TEXT
  }

  imageCardTourPreviewTextSeen (): void {
    if (this.step === this.NEW_STORY_STEP_ADD_CARD) {
      return
    }

    this.step = this.STEP_DONE
  }

  // video card tour

  isVideoCardTourUrlStep (): boolean {
    return ( this.step === this.VIDEO_CARD_STEP_URL )
  }

  isVideoCardTourPreviewVideoStep (): boolean {
    return ( this.step === this.VIDEO_CARD_STEP_PREVIEW_VIDEO )
  }

  videoCardTourCardAdded (): void {
    this.step = this.VIDEO_CARD_STEP_URL
  }

  videoCardTourUrlUpdated (): void {
    this.step = this.VIDEO_CARD_STEP_PREVIEW_VIDEO
  }

  videoCardTourPreviewVideoSeen (): void {
    if (this.step === this.NEW_STORY_STEP_ADD_CARD) {
      return
    }

    this.step = this.STEP_DONE
  }

  // preview card tour

  isPreviewFormTourTextStep (): boolean {
    return ( this.step === this.PREVIEW_FORM_STEP_PREVIEW_TEXT )
  }

  previewFormOpened (): void {
    this.previewFormTour = true
    this.step = this.PREVIEW_FORM_STEP_PREVIEW_TEXT
  }

  previewFormTextSaved (): void {
    this.step = this.STEP_DONE
  }

  // product tour
  // XXX placeholder function until i think of a better way to do this

  isProductTour (): boolean {
    return false
  }
}
