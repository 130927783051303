
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';






import { environment } from '../../../../environments/environment';

import { User } from '../../models/user/user.model';
import { Company } from '../../models/company/company.model';
import { Story } from '../../models/story/story.model';
import { AuthService } from '../auth/auth.service';
import { ErrorService } from '../error/error.service';
import { S3UploadService } from '../s3/s3.service';
import { AddNotification } from '../../helpers/notification.helpers';

@Injectable()
export class StoryService {
  company: Company

  constructor (
    private router: Router,
    private s3: S3UploadService,
    private http: HttpClient,
    private authService: AuthService,
    private errorService: ErrorService
  ) {}

  generateQuery (data: any): string {
    return Object.keys(data).map((key) => {
      return [key, data[key]].map(encodeURIComponent).join('=')
    }).join('&')
  }

  buildPaginationQuery (query: any, pagination: any): string {
    if ( !pagination || pagination.perpage === 'all' ) {
      query['per_page'] = -1
    } else {
      query['per_page'] = pagination.perpage
      query['page'] = pagination.page
    }

    return query
  }

  getStories (filter?: any, pagination?: any): Observable<any> {
    const filters = environment.config['STORY_FILTERS'], query = {}

    if ( filter && filters[filter] ) {
      query[filter] = true
    }

    if ( this.authService.user.company.id ) {
      query['company_id'] = this.authService.user.company.id
    }

    this.buildPaginationQuery(query, pagination)
    const url = `/stories?` + this.generateQuery(query)

    return this.http
      .get<any>(url, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return {
          pages: response.headers.get('X-PAGINATION-PAGE-COUNT'),
          stories: response.body.map(item => new Story(item))
        }
      }),catchError(this.errorService.handleError),)
  }

  cloneStory (id: number): Observable<Story> {
    const url = `/stories/${id}/duplicate`
    return this.http
      .post<Story>(url, {}).pipe(
      map((data) => {
        return new Story(data)
      }),catchError(this.errorService.handleError),)
  }

  getStory (id: number): Observable<Story> {
    const url = `/stories/${id}`
    return this.http
      .get<Story>(url).pipe(
      map((data) => {
        return new Story(data)
      }),catchError(this.errorService.handleError),)
  }

  saveStory (story: Story): Observable<Story> {
    if ( story.id ) {
      const url = `/stories/${story.id}`
      story.updated_at = new Date()
      return this.http
        .put<Story>(url, { story }).pipe(
        map((data) => {
          return new Story(data)
        }),catchError(this.errorService.handleError),)
    } else {
      const url = '/stories'
      return this.http
        .post<Story>(url, { story }).pipe(
        map((data) => {
          return new Story(data)
        }),catchError(this.errorService.handleError),)
    }
  }

  reviewStory (storyId: number): Observable<boolean> {
    const url = `/stories/${storyId}/request-review`
    return this.http
      .put(url, {}).pipe(
      map((data) => {
        return true
      }),catchError(this.errorService.handleError),)
  }

  publishStory (story: Story): Observable<Story> {
    const url = `/stories/${story.id}/publish`
    return this.http
      .put<Story>(url, { story }).pipe(
      map((data) => {
        return new Story(data)
      }),catchError(this.errorService.handleError),)
  }

  unpublishStory (story: Story): Observable<Story> {
    const url = `/stories/${story.id}/unpublish`
    return this.http
      .put<Story>(url, { story }).pipe(
      map((data) => {
        return new Story(data)
      }),catchError(this.errorService.handleError),)
  }

  archiveStory (story: Story): Observable<Story> {
    const url = `/stories/${story.id}/archive`
    return this.http
      .put<Story>(url, { story }).pipe(
      map((data) => {
        return new Story(data)
      }),catchError(this.errorService.handleError),)
  }

  unarchiveStory (story: Story): Observable<Story> {
    const url = `/stories/${story.id}/unarchive`
    return this.http
      .put<Story>(url, { story }).pipe(
      map((data) => {
        return new Story(data)
      }),catchError(this.errorService.handleError),)
  }

  deleteStory (story: Story): Observable<Story> {
    const url = `/stories/${story.id}`
    return this.http
      .delete<Story>(url).pipe(
      catchError(this.errorService.handleError))
  }

  assignStory (story: any): any {
    const url = '/stories/assign'
    return this.http
      .post(url, { story }).pipe(
      map((data) => {
        return data
      }),catchError(this.errorService.handleError),)
  }
}
