import config from "./../../configs/stage.json";

export const environment = {
  production: false,
  config: config,
};

/* tslint:disable */
console.info(config);
/* tslint:enable */
