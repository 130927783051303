import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class StoryPublishService {
    notify: Subject<any>
    dirtyCard = false

    constructor () {
        this.notify = new Subject()
    }

    markAsDirty () {
        this.dirtyCard = true
    }

    markAsClean () {
        this.dirtyCard = false
    }

    fire () {
        this.notify.next(this.dirtyCard)
    }
}
