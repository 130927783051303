export class Subcategory {
    id: number
    name: string
    parent_id: number
    company_id: number
    created_at: Date
    updated_at: Date
    children: any
    parent?: any

    constructor(data: any = {}) {
        Object.assign(this, data)
    }
}

export class Category {
    id: number
    name: string
    parent_id?: number
    company_id: number
    created_at: Date
    updated_at: Date
    children: Subcategory[]
    parent?: any

    constructor(data: any = {}) {
        Object.assign(this, data)

        if (!this.children) {
            this.children = []
        }

        this.children = this.children.map(subcategory => {
            return new Subcategory(subcategory)
        })
    }
}
