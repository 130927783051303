import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { UserInviteItemComponent } from './user-invite-item.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    declarations: [
        UserInviteItemComponent
    ],
    exports: [
        UserInviteItemComponent
    ],
    providers: []
})
export class UserInviteItemModule {}
