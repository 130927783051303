export class ProductSummary {
  total: number
  linked: number
  unlinked: number
  drafts: number

  constructor(data: any = {}) {
    Object.assign(this, data)
  }
}
