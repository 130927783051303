export class StorySummary {
  total: number
  published: number
  drafts: number
  archived: number

  constructor(data: any = {}) {
    Object.assign(this, data)
  }
}
