import { Component } from '@angular/core';

import { User, Company } from '../../models';

import { UserHasAccess } from '../../helpers';

import { AuthService, TeamService, SharedService } from '../../services';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: [ './sidebar.component.scss' ]
})
export class SidebarComponent {
    user: User
    company: Company

    UserHasAccess
    subscribers: any = []
    isActive = false
    selectedMainMenu = ''

    constructor(
        private sharedService: SharedService,
        private authService: AuthService,
        private teamService: TeamService
    ) {
        this.user = this.authService.user
        this.company = this.user.company

        this.UserHasAccess = (path) => {
            return UserHasAccess(this.user, this.teamService.teams, path)
        }

        this.subscribers.push(this.authService.user$.subscribe((user) => {
            this.user = user
            this.company = this.user.company || new Company({})
        }))
    }

    eventCalled () {
        this.isActive = !this.isActive
    }

    addExpandClass (element: any) {
        if ( element === this.selectedMainMenu ) {
            this.selectedMainMenu = '0'
        } else {
            this.selectedMainMenu = element
        }
    }

    openSelectModal (type) {
        this.sharedService.triggerModal(type, story => {})
    }
}
