export class BillingAddress {
  first_name: string
  last_name: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  country: string

  constructor(data: any = {}) {
    Object.assign(this, data)
  }
}
