import { Modal } from '../models/modal/modal.model';

export let MODALS = []

export const SetModal = (modal: any, timeout?: number, callback?: any) => {
    modal.id = GenerateModalId()
    modal = new Modal(modal)
    modal.show = true
    if ( MODALS.indexOf(modal) > -1 ) {
        return
    }
    MODALS.push(modal)
    if ( modal.confirmation ) {
        MODALS.length = 1
    }
    if ( timeout && typeof timeout === 'number' ) {
        setTimeout(() => {
            DestroyModal(modal)
            if ( callback ) {
                callback()
            }
        }, timeout)
    }
}

export const DestroyModal = (modal: any) => {
    const index = MODALS.indexOf(modal)
    DestroyModalByIndex(index)
}

export const DestroyModalByIndex = (index: number) => {
    MODALS.splice(index, 1)
}

export const DestroyAllModals = () => {
    MODALS.splice(0, MODALS.length)
}

export const GenerateModalId = () => {
    /* tslint:disable no-bitwise */
    return ( ( new Date().getTime() / 1000 | 0 )
               .toString(16) + 'xxxxxxxxxxxxxxxx' )
               .replace(/[x]/g, () => {
      return ( Math.random() * 16 | 0 ).toString(16)
    }).toLowerCase()
}
