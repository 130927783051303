import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PlyrComponent } from 'ngx-plyr';
import Plyr from 'plyr';

import { Story } from '../../models/story/story.model';
import { Choice } from '../../models/choice/choice.model';

import { MODALS, DestroyModal, DestroyAllModals } from '../../helpers/modal.helpers';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: [ './modal.component.scss' ]
})
export class ModalComponent {

    @ViewChild(PlyrComponent) plyr: PlyrComponent
    @Output() onSelect: EventEmitter<any> = new EventEmitter()

    modals = MODALS
    page = 1
    options = {
        controls: [ 'play', 'progress', 'current-time', 'mute', 'volume' ]
    }
    videoSources: Plyr.Source[] = [
      {
        src: 'bTqVqk7FSmY',
        provider: 'youtube',
      },
    ]

    constructor (public router: Router,
                 private modalService: NgbModal) {}

    hasContent (modal) {
        return !modal.loading &&
               modal.content &&
               modal.content.length > 0
    }

    isVideo (modal) {
        return !modal.loading &&
               modal.sources &&
               modal.sources[0] &&
               modal.sources[0].src &&
               modal.type === 'video'
    }

    isEditAdapt (modal) {
        return !modal.loading &&
               ( modal.type === 'edit' ||
                 modal.type === 'adapt' )
    }

    isLinkStories (modal) {
        return !modal.loading &&
               modal.type === 'link-stories'
    }

    isLinkProducts (modal) {
        return !modal.loading &&
               modal.type === 'link-products'
    }

    isChoices (modal) {
        return !modal.loading &&
               modal.choices &&
               modal.choices.length > 0
    }

    isLoading (modal) {
        return modal.loading
    }

    closeModal (modal) {
        DestroyModal(modal)
        if ( modal.callback ) {
            modal.callback()
        }
    }

    unsetSelected (modal, item) {
        if ( !modal.selected ) {
            return
        }
        if ( modal.selected.indexOf(item) > -1 ) {
            modal.selected.splice(modal.selected.indexOf(item), 1)
        }
    }

    setSelected (modal, item) {
        if ( !modal.selected ) {
            modal.selected = []
        }
        modal.selected.push(item)
    }

    changeSelection (event, modal, item) {
        if ( event.target.checked ) {
            this.setSelected(modal, item)
        } else {
            this.unsetSelected(modal, item)
        }
    }

    isChecked (modal, item) {
        const found = modal.selected.find(i => i.id === item.id)
        if ( found ) {
            return true
        } else {
            return null
        }
    }

    setPage (modal, page) {
        modal.pagination.forEach(pg => {
            if ( pg.visited ) {
                pg.visited = false
            }
        })
        page.visited = true
        modal.loading = true
        modal.fetch(page.index, (items, paagination) => {
            modal.paagination = paagination
            modal.items = items
            modal.loading = false
        })
    }

    selectChoice (event, modal, choice) {
        choice.event = event
        choice.selected = modal.selected || []
        if ( modal.returnValue ) {
            const element = document.getElementById(modal.returnValue) || {}
            choice.returnValue = element['value'] || ''
        }
        this.onSelect.emit([ choice, modal.type ])
        if ( !choice.keep ) {
            DestroyModal(modal)
        }
        if ( modal.callback ) {
            modal.callback(choice)
        }
    }

    selectStory (modal, story) {
        this.onSelect.emit([ story, modal.type ])
        DestroyModal(modal)
        if ( modal.callback ) {
            modal.callback(story)
        }
    }

    @HostListener('window:keyup', ['$event'])
    keyPress (event) {
        if ( event.code === 'Escape' && MODALS[0] ) {
            this.closeModal(MODALS[0])
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize (event) {
        DestroyAllModals()
    }

}
