import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  OnChanges,
  AfterContentChecked,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject } from "rxjs";

import { Card, Story, Product } from "../../models";

import {
  ExifService,
  CardService,
  StoryService,
  S3UploadService,
  SharedService,
} from "../../services";

import { FileSystemFileEntry, FileSystemDirectoryEntry } from "../../types";

import { AddNotification } from "../../helpers/notification.helpers";
import { environment } from "../../../../environments/environment";

// copied from https://stackoverflow.com/a/18650828
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024,
    dm = decimals < 0 ? 0 : decimals,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

@Component({
  selector: "app-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.scss"],
})
export class ImageUploadComponent
  implements OnChanges, OnInit, OnDestroy, AfterContentChecked
{
  @Input() imageURL: string;
  @Input() lowresURL: string;
  @Input() previewURL: string;
  @Input() name: string;
  @Input() title: string;
  @Input() dimensions: string;
  @Input() uploads: Subject<any>;
  @Input() product: Product;
  @Input() story: Story;
  @Input() card: Card;
  @Input() image: any;
  @Input() type: any;
  @Input() restrictUploadType: string;
  @Input() emitter: any;
  // XXX shouldn't pass the form object into this component, bad separation of concerns
  @Input() form: any;
  @Input() add: boolean;
  @Input() remove: boolean;
  @Input() cover: boolean;
  @Input() preview: any;
  @Input() tour: any;
  @Input() logo: boolean;
  @Input() multiple: boolean;
  @Input() disabled: boolean;
  @Input() showMediaLibraryImportButton = true;
  @Input() wrapperClass: string;

  @ViewChild("imageUploadTip") public imageUploadTip: NgbTooltip;

  dragging: boolean;
  uploading: boolean;
  coverClass = "";
  imageClass = "";
  labelClass = "";
  blankClass: string;
  subscribers: any = [];

  constructor(
    private uploadService: S3UploadService,
    private exifService: ExifService,
    private sharedService: SharedService,
    private router: Router
  ) {
    if (!this.dimensions) {
      this.dimensions = "640 x 944";
    }

    this.blankClass = "font-size-xs";
  }

  ngOnInit() {
    if (this.cover) {
      this.coverClass += " into-cover";
      this.labelClass += " into-cover";
      this.blankClass = "font-size-sm into-image-blank";
    }

    if (this.preview) {
      this.coverClass += " into-preview";
      this.labelClass += " into-preview";
      this.wrapperClass = "into-preview-wrapper";
    }

    if (this.logo) {
      this.imageClass += " into-logo thumb-upload-contain";
      this.labelClass += " into-logo-blank";
      this.coverClass += " into-preview-logo";
      this.wrapperClass = "into-logo-wrapper";
    }
  }

  ngAfterContentChecked() {
    this.checkForTips();
  }

  ngOnDestroy() {
    this.subscribers.forEach((subscriber) => {
      subscriber.unsubscribe();
    });
  }

  uploadTooltipTitle() {
    return this.tour.isNew()
      ? "STEP " + this.tour.getImageUploadStepNumber() + ":"
      : "NEXT STEP:";
  }

  uploadTooltipText() {
    return this.tour.isNew()
      ? "Upload your cover image"
      : "Upload your card image";
  }

  uploadTooltipContent(uploadTipContent, videoTipContent) {
    return this.shouldShowUploadTooltip()
      ? uploadTipContent
      : this.shouldShowVideoTooltip()
      ? videoTipContent
      : null;
  }

  imageUploadTooltipTitle(title, logo) {
    return title ? title : "Upload " + logo ? "logo" : "image";
  }

  browseLabel() {
    if (this.multiple) {
      if (this.cover) {
        return "Drag image folder here";
      } else {
        return "Drag image folder here or";
      }
    } else {
      if (this.cover) {
        return "Drag image here";
      } else {
        return "Drag image here or";
      }
    }
  }

  importLabel() {
    if (this.multiple) {
      if (this.cover) {
        return "choose images from";
      } else {
        return "or choose images from";
      }
    } else {
      if (this.cover) {
        return "choose image from";
      } else {
        return "or choose image from";
      }
    }
  }

  shouldShowUploadTooltip() {
    return (
      this.tour &&
      this.tour.isStoryTour() &&
      (this.tour.isImageUploadStep() ||
        this.tour.isImageCardTourImageUploadStep())
    );
  }

  shouldShowVideoTooltip() {
    return (
      this.tour &&
      this.tour.isProductTour() &&
      !this.disabled &&
      this.tour.isWatchVideoStep()
    );
  }

  checkForTips() {
    if (!(this.imageUploadTip && this.tour)) {
      return;
    }

    if (
      this.imageUploadTip &&
      (this.shouldShowUploadTooltip() || this.shouldShowVideoTooltip())
    ) {
      this.imageUploadTip.open();
    }
  }

  ngOnChanges(changes) {
    this.checkForTips();
  }

  onDrop(event) {
    setTimeout(() => (this.dragging = false), 500);
  }

  addCover(event) {
    this.imageURL = null;
    this.lowresURL = null;
    this.previewURL = null;

    if (this.form) {
      this.form.markAsPristine();
    }

    this.emitter(
      this.type,
      { url: this.imageURL, preview: this.previewURL },
      {
        card: this.card,
        story: this.story,
        product: this.product,
        image: this.image,
      }
    );
  }

  removeCover(event) {
    if (this.card.id) {
      this.subscribers.push(
        this.uploadService
          .deleteCardMedia(this.card.id, this.card.card_type, "thumbnail_image")
          .subscribe((response) => {
            this.imageURL = null;
            this.lowresURL = null;
            this.previewURL = null;

            if (this.form) {
              this.form.markAsPristine();
            }

            if (this.card.card_type === "video_card") {
              this.card["display_thumbnail_image"] = false;
              this.card["thumbnail_image_asset_id"] = null;
              this.card["thumbnail_image_asset_url"] = null;
              this.card["thumbnail_image_url_local"] = null;
              this.card["thumbnail_image_asset_lowres_url"] = null;
              this.card["thumbnail_image_asset_widget_url"] = null;
            }

            this.emitter(
              this.type,
              { url: this.imageURL, preview: this.previewURL },
              {
                card: this.card,
                story: this.story,
                product: this.product,
                image: this.image,
              }
            );
          })
      );
    } else {
      this.imageURL = null;
      this.lowresURL = null;
      this.previewURL = null;
      this.emitter(
        this.type,
        { url: this.imageURL, preview: this.previewURL },
        {
          card: this.card,
          story: this.story,
          product: this.product,
          image: this.image,
        }
      );
    }
  }

  readFile(file, method): Observable<any> {
    return Observable.create((observer) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const buffer = event.target["result"];
        observer.next(buffer);
        observer.complete();
      };

      reader.onerror = (err) => {
        observer.error(err);
        AddNotification(
          {
            title: "Error",
            text: "Image upload failed",
          },
          3000
        );
      };

      const fn = reader[method].bind(reader);

      fn(file);
    });
  }

  uploadFile(file, callback) {
    // not sure how to pass extra data through the observable
    // chain, so instead populating this object at each step
    const upload = {
      file,
      buffer: null,
      url: null,
    };

    this.subscribers.push(
      this.readFile(file, "readAsArrayBuffer").subscribe((buffer) => {
        upload.buffer = buffer;
        this.subscribers.push(
          this.uploadService.getUploadLink(Date.now()).subscribe((result) => {
            upload.url = result.url;
            this.subscribers.push(
              this.uploadService
                .uploadMedia(result.url, this.getFileMimeType(file), buffer)
                .subscribe(() => {
                  return callback(upload);
                })
            );
          })
        );
      })
    );
  }

  triggerFileUpload(event) {
    event.preventDefault();
    event.stopPropagation();

    const element = this.multiple
      ? document.querySelector(".file-upload-input-multiple")
      : document.querySelector(".file-upload-input");

    if (element && element["click"]) {
      element["click"]();
    }
  }

  onReplaceClick(event) {
    if (this.story) {
      this.sharedService.triggerModal("choose_image_source", (choice) => {
        if (choice && choice.action === "library") {
          const args = { storyCard: this.type };

          if (this.card && this.card.id) {
            args["cardId"] = this.card.id;
          }

          this.emitter("trigger_replace", {}, args);
        } else if (choice && choice.action === "computer") {
          this.triggerFileUpload(event);
        }
      });
    } else {
      this.triggerFileUpload(event);
    }
  }

  onImportClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const args = { storyCard: this.type };

    if (this.card && this.card.id) {
      args["cardId"] = this.card.id;
    }

    this.emitter("trigger_import", {}, args);
  }

  triggerDropFile(event) {
    event.preventDefault();
    event.stopPropagation();

    const parent = event.target.parentElement.parentElement,
      element = parent
        ? parent.querySelector(".file-upload-input-multiple")
        : false;

    if (element && element["click"]) {
      element["click"]();
    }
  }

  validateFile({ file, reject }) {
    const limits = environment.config["UPLOAD_SIZE_LIMITS"],
      acceptedTypes = environment.config["ACCEPTABLE_FILE_TYPES"],
      fileMimeType = this.getFileMimeType(file),
      fileType = this.getFileType(fileMimeType),
      limit = limits[fileType] ? limits[fileType] : limits.general;

    const badTypeErrorMessage = () =>
      this.restrictUploadType === "video"
        ? "Only mp4 and mov videos are acceptable"
        : "Only jpg, png, and gif images are acceptable";

    if (fileType !== this.restrictUploadType) {
      reject(Error(badTypeErrorMessage()));
      return false;
    }

    if (acceptedTypes.indexOf(fileMimeType) === -1) {
      reject(Error(badTypeErrorMessage()));
      return false;
    }

    if (file.size > limit) {
      reject(Error("File too big, limit is: " + formatBytes(limit, 0)));
      return false;
    }

    return true;
  }

  handleFile(event) {
    if (this.disabled || !event.target.files || event.target.files.length < 1) {
      return;
    }

    this.uploading = true;
    this.emitter("uploading", true);

    const promises = [],
      promiseErrors = [];

    Array.from(event.target.files).forEach((file) => {
      if (file && file instanceof Blob) {
        promises.push(
          new Promise((resolve, reject) => {
            const valid = this.validateFile({
              file: file,
              reject: reject,
            });

            if (!valid) {
              this.uploading = false;
              this.emitter("uploading", false);
              return;
            }

            if (this.type === "video") {
              this.subscribers.push(
                this.readFile(file, "readAsDataURL").subscribe((preview) => {
                  // XXX might generate a thumbnail using an npm library
                  if (this.type === "video") {
                    preview =
                      "/assets/images/testing/video-thumbnail-coming-soon.png";
                  }

                  this.previewURL = preview;

                  this.uploadFile(file, (upload) => {
                    this.uploads.next({
                      lowresURL: this.lowresURL,
                      uploadURL: upload.url,
                      previewURL: preview,
                    });

                    return resolve({
                      preview,
                      url: upload.url,
                      lowres: this.lowresURL,
                    });
                  });
                })
              );
            } else {
              // XXX exifService messes up animated gifs
              // easiest solution is to not use it when there is a gif. this could potentially
              // cause problems later on but it's not worth investing time in it for now.
              if (this.getFileMimeType(file) === "image/gif") {
                this.subscribers.push(
                  this.readFile(file, "readAsDataURL").subscribe((preview) => {
                    this.previewURL = preview;
                    this.uploadFile(file, (upload) => {
                      this.previewURL = preview;

                      this.uploads.next({
                        lowresURL: this.lowresURL,
                        uploadURL: upload.url,
                        previewURL: preview,
                      });

                      return resolve({
                        preview,
                        url: upload.url,
                        lowres: this.lowresURL,
                      });
                    });
                  })
                );
              } else {
                this.exifService.getOrientedImage(file).then((orientedBlob) => {
                  this.subscribers.push(
                    this.readFile(orientedBlob, "readAsDataURL").subscribe(
                      (preview) => {
                        this.previewURL = preview;
                        this.uploadFile(orientedBlob, (upload) => {
                          this.previewURL = preview;
  
                          this.uploads.next({
                            lowresURL: this.lowresURL,
                            uploadURL: upload.url,
                            previewURL: preview,
                          });
  
                          return resolve({
                            preview,
                            url: upload.url,
                            lowres: this.lowresURL,
                          });
                        });
                      }
                    )
                  );
                });
              }
            }
          }).catch((error) => {
            promiseErrors.push(error);
            return null;
          })
        );
      }
    });

    Promise.all(promises).then((values) => {
      let message;
      const goodValues = values.filter((value) => value !== null);

      if (Object.keys(promiseErrors).length === 0) {
        if (this.multiple) {
          let imageString = " Image";
          if (values.length > 1) {
            imageString += "s";
          }
          message = values.length + imageString + " uploaded successfully.";
          if (this.type === "product_image") {
            message += " Allow up to 15 minutes for processing.";
          }
        } else {
          message =
            (this.type === "video" ? "Video" : "Image") +
            " uploaded successfully";
        }

        AddNotification(
          {
            title: "Success",
            text: message,
            hideConfirm: true,
          },
          6000
        );
      } else {
        if (this.multiple) {
          message =
            "Some images failed due to unsupported file type or file size";
        } else {
          message = promiseErrors[0].message;
        }

        AddNotification(
          {
            title: "Error",
            text: message,
          },
          3000
        );
      }

      if (this.multiple) {
        this.imageURL = null;
        this.lowresURL = null;
        this.previewURL = null;
        this.emitter(this.type, goodValues, { card: this.card });
      } else {
        if (goodValues.length > 0 && goodValues[0] && goodValues[0].url) {
          this.emitter(
            this.type,
            {
              url: goodValues[0].url,
              preview: goodValues[0].preview,
              lowres: goodValues[0].lowres,
            },
            { card: this.card }
          );

          if (this.form) {
            this.form.markAsDirty();
          }
        }
      }

      this.uploading = false;
      this.emitter("uploading", false);
    });
  }

  isHiddenFile(fileEntry: FileSystemFileEntry) {
    return fileEntry.name.indexOf(".") === 0;
  }

  dropped(files) {
    const promises = [];

    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        if (this.isHiddenFile(fileEntry)) {
          continue;
        }

        promises.push(
          new Promise((resolve, reject) => {
            fileEntry.file((file: File) => {
              resolve(file);
            });
          })
        );
      }
    }

    Promise.all(promises).then((values) => {
      this.handleFile({ target: { files: values } });
    });
  }

  getFileMimeType(file: any) {
    // there seems to be a bug in firefox when uploading folders, it doesn't set the
    // file.type attribute, so we need to detect that and patch it with a manual detection
    if (!file.type && file.name.indexOf(".") !== -1) {
      const fileExt = file.name.substr(file.name.lastIndexOf(".") + 1);

      switch (fileExt) {
        case "jpg":
        case "jpeg":
          return "image/jpeg";
        case "png":
          return "image/png";
        case "gif":
          return "image/gif";
        case "mp4":
          return "application/mp4";
        case "mov":
          return "video/quicktime";
        default:
          return file.type;
      }
    } else if (file.type === "video/mp4") {
      return "application/mp4";
    } else {
      return file.type;
    }
  }

  getFileType(fileMimeType: string) {
    switch (fileMimeType) {
      case "image/jpeg":
      case "image/png":
      case "image/gif":
        return "image";

      case "application/mp4":
      case "video/mp4":
      case "video/quicktime":
        return "video";

      default:
        return fileMimeType;
    }
  }
}
