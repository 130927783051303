
import {throwError as observableThrowError,  Observable ,  of } from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { User } from '../../models/user/user.model';
import { Company } from '../../models/company/company.model';
import { Product } from '../../models/product/product.model';
import { AuthService } from '../auth/auth.service';
import { ErrorService } from '../error/error.service';






@Injectable()
export class ProductService {
  constructor (
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private errorService: ErrorService
  ) {}

  generateQuery (data: any): string {
    return Object.keys(data).map((key) => {
      return [key, data[key]].map(encodeURIComponent).join('=')
    }).join('&')
  }

  buildPaginationQuery (query: any, pagination: any): string {
    if ( !pagination || pagination.perpage === 'all' ) {
      query['per_page'] = -1
    } else {
      query['per_page'] =
        pagination.perpage
      query['page'] =
        pagination.page
    }
    return query
  }

  buildFilterQuery (query: any, filter?: any): string {
    if ( !filter ) {
      return query
    }
    if ( filter === 'linked' ) {
      query['linked'] = true
    }
    if ( filter === 'unlinked' ) {
      query['linked'] = false
    }
    return query
  }

  getProducts (filter?: any, pagination?: any): Observable<any> {
    if ( !this.authService.user.company.id ) {
      return observableThrowError('No company')
    }
    return this.getCompanyProducts(this.authService.user.company.id, pagination, filter)
  }

  getStoryProducts (story_id: number, pagination?: any, filter?: any): Observable<any> {
    const query = { story_id }
    this.buildFilterQuery(query, filter)
    this.buildPaginationQuery(query, pagination)
    const url = `/products?` + this.generateQuery(query)
    return this.http
      .get<Array<Product>>(url, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return {
          pages: response.headers.get('X-PAGINATION-PAGE-COUNT'),
          products: response.body.map(item => new Product(item))
        }
      }),catchError(this.errorService.handleError),)
  }

  getCompanyProducts (company_id: number, pagination?: any, filter?: any): Observable<any> {
    const query = { company_id }
    this.buildFilterQuery(query, filter)
    this.buildPaginationQuery(query, pagination)
    const url = `/products?` + this.generateQuery(query)
    return this.http
      .get<Array<Product>>(url, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return {
          pages: response.headers.get('X-PAGINATION-PAGE-COUNT'),
          products: response.body.map(item => new Product(item))
        }
      }),catchError(this.errorService.handleError),)
  }

  getProduct (product_id: number): Observable<Product> {
    const url = `/products/${product_id}`
    return this.http
      .get<Product>(url).pipe(
      map((data) => {
          return new Product(data)
      }),catchError(this.errorService.handleError),)
  }

  saveProduct (product: Product): Observable<Product> {
    if ( product.id ) {
      const url = `/products/${product.id}`
      return this.http
        .put<Product>(url, { product }).pipe(
        map((data) => {
          return new Product(data)
        }),catchError(this.errorService.handleError),)
    } else {
      const url = `/products`;
      return this.http
        .post<Product>(url, { product }).pipe(
        map((data) => {
          return new Product(data)
        }),catchError(this.errorService.handleError),)
    }
  }

  deleteProduct (product: Product): Observable<Product> {
    const url = `/products/${product.id}`
    return this.http
      .delete<Product>(url).pipe(
      catchError(this.errorService.handleError))
  }

}
