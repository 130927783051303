import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';

import { User } from '../../models';
import { AuthService } from '../../services';

@Component({
    selector: 'app-team-item',
    templateUrl: './team-item.component.html',
    styleUrls: ['./team-item.component.scss']
})
export class TeamItemComponent implements OnDestroy, OnInit {
    @Input() item: User
    @Input() check: boolean
    @Input() checked: boolean
    @Input() readonly: boolean
    @Input() emitter: any

    user: User

    duplicate: boolean
    subscribers: any = []

    constructor (private authService: AuthService) {
        this.user = this.authService.user
    }

    ngOnInit () {}

    ngOnDestroy () {
        this.subscribers.forEach(subscriber => {
            subscriber.unsubscribe()
        })
    }

    radioBtnClick (event) {
        const { id, checked } = event.target
        if ( checked ) {
            this.emitter('team_selected', null, { id })
        } else  {
            this.emitter('team_unselected', null, { id })
        }
    }
}
