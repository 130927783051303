import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'round'
})
export class RoundPipe implements PipeTransform {

  constructor () {}

  transform (num) {
    return Math.round(num)
  }

}
