
import {throwError as observableThrowError,  Subject ,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';





import { User, Team } from '../../models';

import { AuthService } from '../auth/auth.service';
import { ErrorService } from '../error/error.service';

@Injectable()
export class TeamService {
    teams: Team[]
    teams$

    constructor (
        private http: HttpClient,
        private router: Router,
        private authService: AuthService,
        private errorService: ErrorService
    ) {
        this.teams$ = new Subject<any>()
        this.teams = []

        this.authService.user$.subscribe(user => {
            // logout detected
            if (!user.id) {
                this.teams = []
            }
        })
    }

    createTeam (team: any): Observable<Team> {
        const url = `/teams`
        return this.http
            .post<Team>(url, { team }).pipe(
            map((data) => {
                const newTeam = new Team(data)
                return newTeam
            }),catchError(this.errorService.handleError),)
    }

    saveTeam (team: any): Observable<Team> {
        if (team.id) {
            const url = `/teams/${team.id}`
            return this.http
                .put<Team>(url, { team }).pipe(
                map((data) => {
                    return new Team(data)
                }),catchError(this.errorService.handleError),)
        } else {
            return this.createTeam(team)
        }
    }

    getTeam (id: number): Observable<any> {
        const url = `/teams/${id}`
        return this.http
            .get<Team>(url).pipe(
            map((data: any) => {
                return new Team(data)
            }),
            catchError(this.errorService.handleError),)
    }

    getTeams (): Observable<any> {
        const url = `/teams`
        return this.http
            .get(url).pipe(
            map((data: any) => {
                this.teams = data.map(team => new Team(team))

                this.teams$.next(this.teams)

                return this.teams
            }),
            catchError((response: any) => {
                if (
                    response && response.status && response.status === 400
                    && response.error && response.error.error
                    && (
                        response.error.error === 'Please update your payment details.'
                        || response.error.error === 'Exceptions::PaymentRequired'
                        || response.error.error.m === 'Exceptions::PaymentRequired'
                    )
                ) {
                    return observableThrowError(response)
                } else {
                    return this.errorService.handleError(response)
                }
            }),)
    }

    emailMember (teamId: number, payload: any): Observable<any> {
        const url = `/teams/${teamId}/email`
        return this.http
            .post(url, payload).pipe(
            catchError(this.errorService.handleError))
    }

    deleteMember (team: Team, member: User): Observable<any> {
        const url = `/teams/${team.id}/members/${member.id}`
        return this.http
            .delete<Team>(url).pipe(
            catchError(this.errorService.handleError))
    }

    replaceMember (team: Team, member: User, replacementMember: User): Observable<any> {
        const url = `/teams/${team.id}/members/${member.id}?replacement_id=${replacementMember.id}`
        return this.http
            .delete<Team>(url).pipe(
            catchError(this.errorService.handleError))
    }
}
