import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, TeamGuard, LoginGuard, ChangesGuard } from './shared';

export const routes: Routes = [{
        path: '',
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
        canActivate: [ AuthGuard, TeamGuard ],
        canDeactivate: [ ChangesGuard ]
    }, {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        canActivate: [ LoginGuard ]
    }, {
        path: 'signup',
        loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule),
        canActivate: [ LoginGuard ]
    }, {
        path: 'forgot',
        loadChildren: () => import('./forgot/forgot.module').then(m => m.ForgotModule),
        canActivate: [ LoginGuard ]
    }, {
        path: 'reset',
        loadChildren: () => import('./reset/reset.module').then(m => m.ResetModule),
        canActivate: [ LoginGuard ]
    }, {
        path: 'confirm',
        loadChildren: () => import('./confirm/confirm.module').then(m => m.ConfirmModule),
        canActivate: [ LoginGuard ]
    }, {
        path: 'reconfirm',
        loadChildren: () => import('./reconfirm/reconfirm.module').then(m => m.ReconfirmModule),
    }, {
        path: 'accept',
        loadChildren: () => import('./accept/accept.module').then(m => m.AcceptModule),
        canActivate: [ LoginGuard ]
    }, {
        path: 'logout',
        loadChildren: () => import('./logout/logout.module').then(m => m.LogoutModule)
    }, {
        path: 'preview',
        loadChildren: () => import('./preview/preview.module').then(m => m.PreviewModule)
    }, {
        path: 'link',
        loadChildren: () => import('./link/link.module').then(m => m.LinkModule)
    }, {
        path: 'not-found',
        loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
    }, {
        path: '**',
        redirectTo: 'not-found',
        pathMatch: 'full'
    }];

const AppRoutes = RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy'
});

@NgModule({
    imports: [ AppRoutes ],
    exports: [ RouterModule ],
    providers: [ ChangesGuard ]
})
export class AppRoutingModule {}
