export class Choice {

  name: string
  type: string

  constructor(data: any = {}) {
    Object.assign(this, data)
  }

}
