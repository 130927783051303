const blank = 'assets/svgs/img-placeholder-TYPE.svg',
    transparent = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

import { Image, ImageRef } from '../image/image.model';
import { environment } from '../../../../environments/environment';

export class Product {
    id?: number
    story_id?: number
    company_id?: number
    name?: string
    bar_code?: string
    description?: string
    images?: any
    productImage: string
    productLowres: string
    productThumbnail: string

    constructor (data: any = {}) {
        Object.assign(this, data)

        if (!this.images) {
            this.images = []
        }

        this.images = this.images.map((image) => {
            return new ImageRef(image)
        })

        this.productImage = this.getProductImage('light')
        this.productLowres = this.getProductLowres()
        this.productThumbnail = this.getProductThumbnail()
    }

    getProductImage (type: string) {
        let placeholder

        if (type) {
            placeholder = blank.replace('TYPE', type)
        } else {
            placeholder = transparent
        }

        if (!this.images[0]) {
            return placeholder
        }

        return (
            this.images[0].product_image_url_local
            || this.images[0].product_image_url
            || placeholder
        )
    }

    getProductLowres () {
        if (!this.images[0]) {
            return transparent
        }

        return (
            this.images[0].product_image_url_local
            || this.images[0].product_image_lowres_url
            || this.images[0].product_image_url
            || transparent
        )
    }

    getProductThumbnail (type: string = null) {
        let placeholder

        if (type) {
            placeholder = blank.replace('TYPE', type)
        } else {
            placeholder = transparent
        }

        if (!this.images[0]) {
            return placeholder
        }

        return (
            this.images[0].product_image_url_local
            || this.images[0].product_image_lowres_url
            || placeholder
        )
    }
}
