export const displayRole = (role: string, pluralize?: boolean): string => {
    const roleDisplayMap = {
        company_admin: 'Storymaster',
        publisher: 'Publisher',
        editor: 'Editor',
        contributor: 'StorySource',
    }

    return roleDisplayMap[role] + (pluralize ? 's' : '')
}
