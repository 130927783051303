import { User, Team } from '../models';

export const getUserTeamIds = (user: User, teams: Team[]): number[] => {
    return teams.filter(team =>
        !!team.members.find(member => member.id === user.id)
    ).map(team => team.id)
}

// XXX not in use
// export const displayCountryAndState = (user: User): string => {
//     let displayAddress = user.profile_address.country

//     if (user.profile_address.country && user.profile_address.state) {
//         displayAddress = displayAddress + ', ' + user.profile_address.state
//     } else if (user.profile_address.state) {
//         displayAddress = user.profile_address.state
//     }

//     return displayAddress
// }

export const displayCityAndState = (user: User): string => {
    let displayAddress = user.profile_address.city

    if (user.profile_address.city && user.profile_address.state) {
        displayAddress = displayAddress + ', ' + user.profile_address.state
    } else if (user.profile_address.state) {
        displayAddress = user.profile_address.state
    }

    return displayAddress
}
