
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable ,  of } from 'rxjs';






import { environment } from '../../../../environments/environment';

import { ErrorService } from '../error/error.service';

@Injectable()
export class GmapsService {

    constructor (private http: HttpClient,
                 private errorService: ErrorService) {}

    geocode (address) {
        const url = 'https://maps.googleapis.com/maps/api/geocode/json' +
                    '?address=' +  address +
                    '&key=' + environment.config['GOOGLE_API_KEY']
        return this.http
          .get(url).pipe(
          map((data) => {
            return data['results'][0] || false
          }),catchError(this.errorService.handleError),)
    }

    reverseGeocode (position) {
        const url = 'https://maps.googleapis.com/maps/api/geocode/json' +
                    '?latlng=' + position.lat + ',' + position.lng +
                    '&key=' + environment.config['GOOGLE_API_KEY']
        return this.http
          .get(url).pipe(
          map((data) => {
            return data['results'][0] || false
          }),catchError(this.errorService.handleError),)
    }

}
